import { createStore } from 'vuex';

const store = createStore({
    state: {
        view: 'home',
        city: null,
        destination: null,
        itinerary: null,
        isPopupVisible: false,
        isMobile: window.innerWidth < 768,
        messages: [],
    },

    mutations: {
        setView(state, view) {
            state.view = view;
        },

        setCity(state, city) {
            state.city = city;
        },

        setItinerary(state, itinerary) {
            state.itinerary = itinerary;
        },

        setDestination(state, destination) {
            state.destination = destination;
        },

        setPopupVisible(state, isVisible) {
            state.isPopupVisible = isVisible;
        },

        setMobile(state, isMobile) {
            state.isMobile = isMobile;
        },

        setMessage(state, message) {
            state.messages.push(message);
            // console.log('setMessage:', state.messages);
        },

        clearMessages(state) {
            state.messages = [];
        },

    },

    actions: {
        updateView({ commit }, view) {
            commit('setView', view);
        },

        updateCity({ commit }, city) {
            commit('setCity', city);
        },

        updateItinerary({ commit }, itinerary) {
            commit('setItinerary', itinerary);
        },


        updateDestination({ commit }, destination) {
            commit('setDestination', destination);
        },

        updatePopupVisible({ commit }, isVisible) {
            commit('setPopupVisible', isVisible);
        },

        updateMobile({ commit }, isMobile) {
            commit('setMobile', isMobile);
        },

        updateMessage({ commit }, message) {
            // console.log('updateMessage:', message);
            commit('setMessage', message);
        },

        clearMessages({ commit }) {
            commit('clearMessages');
        },

    }
});

export default store