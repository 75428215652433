<template>
  <div class="google-map">
    <GoogleMap
      :api-key="apiKey"
      style="width: 100%; height: 100%;"
      :center="coordinates"
      :zoom="12"
    >
      <Marker :options="{ position: coordinates }" />
    </GoogleMap>
  </div>
</template>

<script>
import { GoogleMap, Marker } from 'vue3-google-map';
import { GOOGLE_MAPS_API_KEY } from '@/config';

// console.log(GOOGLE_MAPS_API_KEY);

export default {
  name: 'GoogleMapView',
  components: {
    GoogleMap,
    Marker
  },
  props: {
    coordinates: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      apiKey: GOOGLE_MAPS_API_KEY,
    };
  },

};

</script>


  
<style scoped>
.google-map {
  width: 96%;
  height: 25vh; /* 1/4 of the viewport height */
  margin: 15px;
  border-radius: 16px;
  border: 2px solid #e0e0e0;
  overflow: hidden;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}


</style>
  