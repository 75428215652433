<template>
    <div class="homepage-header">
        <HomeHeader  />
    </div>
    <div class="homepage-body">
        <!-- Popup content goes here -->
        <PopularDestination @toggle-popup-submitted="submitTogglePopup"/>
        <TrendingSearch @message-submitted="addUserMessage" @toggle-popup-submitted="submitTogglePopup" />
        <PopularItinerary @toggle-popup-submitted="submitTogglePopup"/>        
    </div> 
</template>


<script>
// import { ref } from 'vue';
// import { stateMessages } from './states/messages.js';
// import { isLoading } from './states/isLoading.js';
import HomeHeader from '@/components/ui/homePage/HomeHeader.vue';
import PopularDestination from '@/components/ui/homePage/PopularDestination.vue';
import PopularItinerary from '@/components/ui/homePage/PopularItinerary.vue';
import TrendingSearch from '@/components/ui/homePage/TrendingSearch.vue';
import { addUserMessage } from '@/components/services/MessageService.js';


export default {
  name: 'HomePage',
  components: {
    HomeHeader,
    PopularDestination,
    TrendingSearch,
    PopularItinerary,
  },
  data() {
    return {
      addUserMessage,
    };
  },
  methods: {
    submitTogglePopup() {
        this.$emit('toggle-popup-submitted');
      }
    },
}

</script>

<style scoped>


.homepage-header {
    /* border-bottom: 1px solid #ccc; */
    display: flex;
    min-height: 190px;
    justify-content: space-between;
    align-items: center;
    font-weight: bold;
}

.homepage-body {
    flex: 1;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    border-radius: 0 0 20px 20px;
}

</style>
