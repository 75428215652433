<template>
    <div class="spinner"></div>
  </template>
  
  <script>
  export default {
    name: 'LoadingSpinner'
  };
  </script>
  
  <style scoped>
  .spinner {
    border: 16px solid var(--grey-line); /* background color of the spinner */
    border-radius: 50%;
    border-top: 16px solid var(--grey-bg);  /* color of the spinning part */
    width: 30px;
    height: 30px;
    margin: 0 auto;
    -webkit-animation: spin 1s linear infinite; /* Safari */
    animation: spin 1s linear infinite;
  }
  
  /* Safari */
  @-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  </style>
  