<template>
    <button @click="handleClick" class="primary-button">
      {{ buttonText }}
    </button>
  </template>
  
  <script>
  import { defineComponent } from 'vue';
  
  export default defineComponent({
    name: 'PrimaryButton',
    props: {
      buttonText: {
        type: String,
        default: 'Explore',
      },
    },
    emits: ['click'],
    setup(_, { emit }) {
      const handleClick = () => {
        emit('click');
      };
  
      return {
        handleClick,
      };
    },
  });
  </script>
  
  <style scoped>
  .primary-button {
    background-color: var(--primary-color);
    color: var(--white);
    border: 2px solid var(--primary-color);
    border-radius: 33px;
    padding: 8px 12px;
    font-size: 0.8em;
    font-weight: bold;
    text-transform: uppercase;
    cursor: pointer;
  }
  
  /* .primary-button:hover {
    background-color: var(--white);
    color: var(--primary-color);
    border: 2px solid var(--primary-color);
  } */
  </style>
  