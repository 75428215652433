<template>
    <div class="app-container">
        <ChatPage />
        <PopupPage />
    </div>
</template>

<script>
import ChatPage from '@/components/ui/chatPage/ChatPage.vue';
import PopupPage from '@/components/ui/popupPage/PopupPage.vue';


export default {
    name: 'App',
    components: {
        ChatPage,
        PopupPage,
    },
}

</script>

<style>

.app-container {
    display: grid;
    grid-template-columns: 1fr 1fr; /* Two columns of equal width */
    background-color: var(--background-color);
    position: relative;
    overflow: hidden; /* Prevent scrolling on the entire screen */
}

/* Responsive design for mobile */
@media (max-width: 768px) {
    .app-container {
        grid-template-columns: 1fr;
        height: 100svh;
    }
}

</style>