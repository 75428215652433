<template>
  <div class="input-container" id="input-container">
    <InputAreaIcon v-if="isMobile" icon="camera_icon.png" altText="Camera" link="link" />
    <InputAreaIcon v-if="isMobile" icon="photo_icon.png" altText="Photo" link="link" />

    <textarea 
        rows="1" 
        v-model="newMessage" 
        @input="adjustTextareaHeight"
        @keyup.enter="submitMessage" 
        type="text" 
        placeholder="Ask anything..." 
        class="input-field" 
    />

    <InputAreaIcon v-if="isMobile" icon="mic_icon.png" altText="Microphone" link="link" />
    <InputAreaIcon icon="send.png" altText="Send" link="link" @click="submitMessage" /> 

  </div>
</template>
  
<script>
import InputAreaIcon from './InputAreaIcon.vue';

export default {
  name: 'InputArea',
  components: {
    InputAreaIcon,
  },
  data() {
    return {
      newMessage: '',
    };
  },
  computed: {
    isMobile() {
      return this.$store.state.isMobile;
    },
  },
  methods: {
    submitMessage() {
      if (this.newMessage.trim() !== '') {
        this.$emit('message-submitted', {
          //id: this.messages.length + 1,
          content: {message: this.newMessage},
          user: true // Assuming the message is from the user
        });
        this.newMessage = ''; // Clear input after sending
        this.$nextTick(() => {
          const textarea = this.$el.querySelector('textarea');
          textarea.style.height = 'auto'; // Reset the textarea height
        });
      }
    },
    adjustTextareaHeight(event) {
      const textarea = event.target;
      textarea.style.height = 'auto'; // Reset the height to auto to shrink it before setting it
      textarea.style.height = `${textarea.scrollHeight}px`; // Set the height based on scrollHeight
    },
  }
};
</script>
  
<style scoped>
.input-container {
  background-color: white;
  /* position: fixed; */
  bottom: 0;
  display: flex;
  /* flex-shrink: 0; */
  align-items: center;
  /* border: 1px solid var(--secondary-color);
  border-radius: 12px; */
  padding: 16px;
  box-sizing: border-box;
  overflow: hidden;
  transition: height 0.2s ease;
}

.input-container InputAreaIcon {
  flex-shrink: 0; /* Prevent icons from shrinking */
}


.input-field {
  font-family: poppins;
  font-size: 1.15em;
  border:  none;

  flex-grow: 1;
  outline: none;
  /* padding: 8px; */
  margin: 0 8px; /* Adds some space between the input and icons */
  resize: none; /* Prevent resizing */
  overflow: hidden; 

}

.input-field .hidden {
  width: 100%; /* Adjust width as needed */
  height: auto; /* Automatically adjust height */
  word-wrap: break-word; /* Break words if needed */
  overflow-wrap: break-word; /* Ensure word breaking on overflow */
  white-space: pre-wrap; /* Preserve whitespace and allow text to wrap */
  resize: none; /* Prevent resizing */
}


@media (max-width: 768px) {
  .input-container {
    padding: 8px;
  }

  .input-field {
    padding-left: 8px;
    border-left: 1px solid var(--border-color);
  } 
}  

</style>
  