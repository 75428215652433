<template>
    <div class="card-container">
        <div class="card-header">
            <img :src="hotelImage()" alt="hotel image" class="card-bg-image" />
            <div class="header-title">
                <img src="@/assets/hotels/hotel_icon.png" alt="hotel" class="title-icon">
                <span>Hotel</span>
            </div>
        </div>
        <div class="card-body">
            <div class="body-heading">
                <h3>{{ formatHotelName(hotel.name) }}</h3>
                <span>{{ randomNumber(7,10,1) }}</span>
            </div>
            <p>{{ 'valid until ' + randomDate(6) }}</p>
            <span>{{ formatPrice(randomNumber(130,520,2)) }}</span> 
            <div class="card-footnote">
                <span>1 night</span>
                <span>1 adult</span>
                <span>{{ randomBedSize() }}</span>
            </div>
            <div class="call-to-action">
                <button @click="selectHotel" class="cta-button">explore</button>
            </div>
        </div>

    </div>
</template>

<script>
import { defineComponent, onMounted  } from 'vue';
// import { useStore } from 'vuex';
// import { useRouter } from 'vue-router';

export default defineComponent({
name: 'HotelCard',
props: {
  hotel: {
    type: Object,
    required: true
  }
},
setup(props) {
  // const store = useStore();
  // const router = useRouter();

  const selectHotel = () => {
    // store.dispatch('updateCity', props.hotel);
    // router.push('/hotel');
    console.log('Hotel selected', props.hotel.name);
  };

  const hotelImage = () => {
    const randmonImage = require('@/assets/hotels/' + 'room' + Math.floor(Math.random() * 10) + '.jpg');
    return randmonImage;
  };

  const randomDate = (months) => {
    const monthsInMilliseconds = months * 30 * 24 * 60 * 60 * 1000; // Approximation of 6 months in milliseconds
    const currentDate = new Date();
    const randomTime = currentDate.getTime() + Math.random() * monthsInMilliseconds;
    const randomDate = new Date(randomTime);

    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return randomDate.toLocaleDateString('en-US', options);
  };

  const formatHotelName = (str) => {
    return str.split(' ').map(word => {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    }).join(' ');
  };

  const randomBedSize = () => {
    const bedSize = ['1 Double Bed', '2 Doubles Beds', '1 Queen Bed', '1 King Bed', '2 Twin Beds'];
    const randomIndex = Math.floor(Math.random() * bedSize.length);
    return bedSize[randomIndex];
  };

  const randomNumber = (min, max, decimal) => {
    return parseFloat((Math.random() * (max - min) + min).toFixed(decimal));
  };

  const formatPrice = (p) => {
    return 'US$' + p;
  };

  onMounted(() => {
    if (!props.hotel) {
      console.log('HotelCard mounted. No hotel selected');
      // router.push('/'); 
    }
    //console.log(props.hotel);
  });


  return {
    selectHotel,
    hotelImage,
    randomDate,
    randomBedSize,
    randomNumber,
    formatHotelName,
    formatPrice,
  };
}
});
</script>
  
<style scoped>
.card-container {
    border: 1px solid var(--border-color);
    /* max-height: 250px; */
    width: var(--card-width);
    height: 397px;
    border-radius: 12px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.25);
    background-color: #fff;
    display: flex;
    flex-direction: column;
    margin-left: 20px;
    margin-top: 10px;
}

.card-header {
    position: relative;
    display: flex;
    align-items: center;
}

.card-bg-image {
    top:0;
    flex-shrink: 0;
    width: 100%;
    height: 212px;
    object-fit: cover;
}

.header-title {
    position: absolute;
    top: 10px;
    left: 10px;
    background-color: white;
    border: none;
    /* padding: 10px 24px 10px 14px; */
    padding: 8px 12px 8px 8px;
    margin: 5px;
    border-radius: 36px;
    cursor: pointer;
    display: flex;
    align-items: center;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.4);
}

.title-icon {
  width: 16px;
  height: 16px;
  margin-right: 8px;
}

.card-body {
  padding: 10px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.card-body p {
    font-size: 0.85em;
    display: -webkit-box;
    margin: 0 0;
    -webkit-line-clamp: 2;  /* Limits the content to 2 lines */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis; /*Adds "..." at the end if the text overflows */
}

.card-body span {
    font-size: 1em;
    font-weight: bold;
    color: var(--primary-color);
}

.body-heading {
    display: flex;
    align-items: top;
    justify-content: space-between;
}

.body-heading h3 {
    font-size: 1em;
    display: -webkit-box;
    margin: 0 0;
    -webkit-line-clamp: 2;  /* Limits the content to 2 lines */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis; /*Adds "..." at the end if the text overflows */
}

.body-heading span {
    font-size: 0.8em;
    font-weight: normal;
    margin: 5px 0 10px 0;
    color: white;
    padding: 3px 5px;
    background-color: var(--primary-color);
    border-top-left-radius: 5px;
    border-bottom-right-radius: 5px;
}


.card-footnote {
  display: flex;
  /* justify-content: space-between; */
  margin: 0 0;
}

.card-footnote span {
    display: flex;
    align-items: center;
    font-size: 0.85em;
    font-weight: normal;
    color: var(--text-color);
    margin: 0;
}

.card-footnote span:not(:first-child) {
    margin-left: 10px;
}

.footnote-icon {
  width: 16px;
  height: 16px;
  margin-right: 3px;
}

/* .cost, .temperature {
  font-size: 0.9em;
  font-weight: bold;
  margin: 0;
} */

.call-to-action {
  display: flex;
  /* justify-content: space-between; */
  margin-top: 8px;
  margin-bottom: 5px;
}


.call-to-action-icon {
  width: 16px;
  height: 16px;
  margin-right: 5px;
}

.cta-button {
  /* margin: 10px 0; */
  background-color: var(--primary-color);
  color: white;
  border: none;
  border-radius: 33px;
  padding: 10px 10px;
  font-size: 0.8em;
  font-weight: bold;
  text-transform: uppercase;
  cursor: pointer;
}

.cta-button:hover {
  background-color: var(--secondary-color);
  color: black;
}



</style>
  