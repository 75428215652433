export const itinerary = 

{
    "city": "Kyoto",
    "country": "Japan",
    "itinerary_title": "Experience the Timeless Beauty of Kyoto",
    "trip_duration": "5 days 4 nights sadkf sdlajf lasdjf lasdfkljasdlfjlasdkjf lasdjf lkasdjfklsd",
    "price": 1400,
    "group_size": "2 people",
    "route": "Kyoto - tokyo - osaka",
    "description": "Kyoto, the cultural heart of Japan, is renowned for its classical Buddhist temples, stunning gardens, imperial palaces, Shinto shrines, and traditional wooden houses. From the iconic Fushimi Inari Shrine to the serene Arashiyama Bamboo Grove, Kyoto offers a blend of history, spirituality, and natural beauty. This itinerary encapsulates the essence of Kyoto, combining must-see landmarks with unique cultural experiences.",
    "available_months": "March - November",
    "itinerary": [
        {
            "city": "Kyoto",
            "days": "Day 1",
            "description": "Start your journey with a visit to the famous Fushimi Inari Shrine. Wander through the thousands of red torii gates, then explore the historic Gion district, known for its traditional wooden machiya houses and geisha culture.",
            "highlights": [
                "Fushimi Inari Shrine: Iconic shrine with thousands of torii gates",
                "Gion District: Traditional area with geisha culture",
                "Kiyomizu-dera: Historic temple with stunning views"
            ],
            "coordinates": {
                "lat": 35.0116,
                "lng": 135.7681
            }
        },
        {
            "city": "Kyoto",
            "days": "Day 2",
            "description": "Discover the serene Arashiyama Bamboo Grove and the nearby Tenryu-ji Temple. Take a scenic boat ride on the Hozu River and visit the Iwatayama Monkey Park for panoramic views of Kyoto.",
            "highlights": [
                "Arashiyama Bamboo Grove: Tranquil bamboo forest",
                "Tenryu-ji Temple: Zen temple with beautiful gardens",
                "Hozu River Boat Ride: Scenic river experience"
            ],
            "coordinates": {
                "lat": 35.0095,
                "lng": 135.6673
            }
        },
        {
            "city": "Kyoto",
            "days": "Day 3",
            "description": "Explore the magnificent Kinkaku-ji (Golden Pavilion) and the nearby Ryoan-ji Temple, famous for its rock garden. In the afternoon, visit the Kyoto Imperial Palace and its beautiful surrounding gardens.",
            "highlights": [
                "Kinkaku-ji: Stunning Golden Pavilion",
                "Ryoan-ji Temple: Famous rock garden",
                "Kyoto Imperial Palace: Historic royal residence"
            ],
            "coordinates": {
                "lat": 35.0394,
                "lng": 135.7292
            }
        },
        {
            "city": "Kyoto",
            "days": "Day 4",
            "description": "Immerse yourself in Kyoto's rich cultural heritage with a tea ceremony experience and a visit to the traditional Nishiki Market. In the evening, enjoy a traditional kaiseki dinner at a local ryokan.",
            "highlights": [
                "Tea Ceremony: Traditional Japanese tea experience",
                "Nishiki Market: Vibrant market with local delicacies",
                "Kaiseki Dinner: Traditional multi-course Japanese meal"
            ],
            "coordinates": {
                "lat": 35.0101,
                "lng": 135.7556
            }
        },
        {
            "city": "Kyoto",
            "days": "Day 5",
            "description": "End your trip with a visit to the Philosopher's Path, a scenic walk along a cherry-tree-lined canal. Explore the nearby Nanzen-ji Temple and enjoy a relaxing boat ride on Lake Biwa.",
            "highlights": [
                "Philosopher's Path: Scenic walk along a cherry-tree-lined canal",
                "Nanzen-ji Temple: Historic temple with beautiful gardens",
                "Lake Biwa Boat Ride: Relaxing experience on Japan's largest lake"
            ],
            "coordinates": {
                "lat": 35.0283,
                "lng": 135.7997
            }
        }
    ]
};