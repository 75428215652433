<template>
  <div class="header-container">
    <div class="header-background">
      <img :src="city.image_url" :alt="city.image_desc" class="hero-image">
    </div>
    <div class="header-content">
      <button class="close-button" @click="closePopup">&#10005;</button>
      <button class="return-button" @click="goHome">&#x21A9;</button>
      <div class="header-title">
        <img src="@/assets/popup/popupHero/pin.png" alt="pin" class="title-icon">
        <span>{{ city.city + ', ' + city.country }}</span>
      </div>
      <div class="icon-container">
        <HeroButton icon="favourite.png" altText="favourite" link="" />
        <HeroButton icon="share.png" altText="share" link="" />
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from 'vuex';
import { onMounted, computed } from 'vue';
import HeroButton from '@/components/ui/homePage/HeroButton.vue';
import { useRouter } from 'vue-router';


export default {
  name: 'CityHeader',
  components: {
    HeroButton,
  },
  setup() {
    const store = useStore();
    const city = computed(() => store.state.city);

    const router = useRouter();

    const closePopup = () => {
      store.dispatch('updatePopupVisible', false);
    };

    const goHome = () => {
      store.dispatch('updateCity', {});
      router.push('/');  // navigate to Home page 
    };

    onMounted(() => {
      if (!city.value) {
        //console.log('CityHeader mounted. No city selected', city.value);
        router.push('/');
      }
    });

    return {
      city,
      closePopup,
      goHome,
    };
  
  },

}


</script>


<style scoped>


.header-container {
  position: relative;
  width: 100%;
}

.header-background img {
  width: 100%;
  height: 25vh; /* Adjust the height as needed */
  object-fit: cover;
}

.hero-image {
  width: 100%;
  max-width: 100%;
  height: 190px;
  border-radius: 12px 12px 0 0;
  top:0;
  flex-shrink: 0;
  object-fit: cover;
}


.header-content {
  position: absolute;
  top: 10px;
  left: 10px;
  right: 10px;
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
}

.header-title {
  background-color: white;
  border: none;
  padding: 10px 24px 10px 14px;
  margin: 5px;
  border-radius: 18px;
  cursor: pointer;
  display: flex;
  align-items: center;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.4);
}

.title-icon {
  width: 15px;
  height: 15px;
  margin-right: 10px;
}

.icon-container {
  display: flex;
  position: absolute;
  right: 0;
}

/* .button-icon {
  background-color: white;
  border: none;
  border-radius: 50%;
  padding: 10px;
  margin: 5px;
  cursor: pointer;
}

.icon-buttons {
  display: flex;
} */

.close-button {
    background-color: white;
    outline: none;
    border-radius: 50%;
    border: none;
    width: 35px;
    height: 35px;
    margin: 0 6px;
    display: none;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
}

.return-button {
    background-color: white;
    outline: none;
    border-radius: 50%;
    border: none;
    width: 35px;
    height: 35px;
    margin: 0 6px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
}

/* Responsive design for mobile */
@media (max-width: 768px) {
    .close-button {
        display: block;
    }
}

</style>