import axios from 'axios';
import { BACKEND_API_URL } from '@/config.js';

export const getDestinations = async (msg) => {
    //console.log('destinationServices > getDestination | msg:', msg);

    try {
        const responses = await axios.post(BACKEND_API_URL + '/destination/',
            {
                prompt: msg
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                    // 'Authorization': 'Bearer token'
                },
                // timeout: 1000
            }
        );
        //console.log('destinationServices > get:', responses);

        //console.log('destinationServices > getDestination | response.data:', responses.data);
        return responses.data;
    } catch (error) {
        console.error('Error:', error);
    }
};

export const getDestination = async (city, country, type=1) => {
    //console.log(`destinationServices > getDestination | city:[${city}] country:[${country}] type:[${type}]`);

    // type = 1: destination card
    // type = 2: destination details

    try {
        const url = BACKEND_API_URL + '/destination?city=' + city + '&country=' + country + '&type=' + type;

        const response = await axios.get(url, 
            {
                headers: {
                    'Content-Type': 'application/json',
                    // 'Authorization': 'Bearer token'
                },
                //timeout: 3000
            }
        );
        //console.log('destinationServices > getDestination response:', response.data);
        return response.data;
    } catch (error) {
        console.error('Error:', error);
    }
};