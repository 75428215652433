<template>
    <div class="popup-container" :class="{ hidden: !isPopupVisible && isMobile }">
        <!-- Conditionally render components based on the view -->
        <CityPage v-if="view === 'city'" :city="city" />
        <ItineraryPage v-else-if="view === 'itinerary'" />
        <DestinationPage v-else-if="view === 'destination'" />
        <!-- HomePage is the default component -->
        <HomePage v-else />
    </div>
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';
import CityPage from '@/components/ui/cityPage/CityPage.vue';  // Import CityPage
import ItineraryPage from '@/components/ui/itineraryPage/ItineraryPage.vue';  // Import ItineraryPage
import DestinationPage from '@/components/ui/DestinationPage/DestinationPage.vue';  // Import DestinationPage
import HomePage from '@/components/ui/homePage/HomePage.vue';  // Import HomePage

export default {
    name: 'PopupContainer',
    components: {
        CityPage,
        ItineraryPage,
        DestinationPage,
        HomePage
    },
    setup() {
        const store = useStore();
        const view = computed(() => store.state.view);
        const isPopupVisible = computed(() => store.state.isPopupVisible);
        const isMobile = computed(() => store.state.isMobile);

        // console.log('PopupContainer', view.value, isPopupVisible.value, isMobile.value);

        return {
            isPopupVisible,
            isMobile,
            view,
        };
    },
}
</script>

<style scoped>
.popup-container {
    position: absolute; 
    top: 0;
    right: 0; 
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: var(--background-color);
    transition: transform 0.3s ease-in-out;
    z-index: 9000;
}

.hidden {
    display: none !important;
}

/* Responsive design for mobile */
@media (max-width: 768px) {
    .popup-container {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 9000;
    }
}
</style>
