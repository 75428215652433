export const searches = [
    "Holiday Paris",
    "Tour itinerary Barcelona",
    "Cultural travel China",
    "Travel itinerary Rome",
    "Night Life in New York",
    "Explore Tokyo",
    "City Guide London",
    "Travel Guide Berlin",
    "One day in Sydney",
    "City Guide Singapore",
    "Road Trip Dubai",
    "Luxury Los Angeles",
    "Travel Guide Bangkok",
    "City Guide Istanbul",
    "Travel Guide Moscow",
    "City Guide Amsterdam",
    "City Guide Prague",
    "City Guide Vienna",
    "Travel Guide Madrid",
    "City Guide Budapest",
    "City Guide Stockholm",
    "City Guide Copenhagen",
];