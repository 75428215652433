<template>
    <div class="card-container">
        <div class="card-header">
            <img :src="imageUrl" :alt="imageAlt" class="card-bg-image" />
            <div class="header-title">
                <img src="@/assets/icons/destination.png" alt="destination" class="title-icon">
                <span>Destination</span>
            </div>
        </div>
        <div class="card-body" v-if="!isLoading">
            <h3>{{ city }}, {{ country  }}</h3>
            <p>{{ description }}</p>
            <div class="card-footnote">
                <span>
                    <img src="@/assets/icons/money-bag.png" alt="Cost" class="footnote-icon">
                    {{ expenseRating  }}
                </span>
                <span>    
                    <img src="@/assets/icons/temperature.png" alt="Temperature" class="footnote-icon">
                    {{ averageTemperature }}
                </span>
            </div>
            <div class="call-to-action">
                <PrimaryButton @click="explore" buttonText="explore" />
                <PublishButton :initialState="false" />
            </div>
        </div>
        <div class="loading-indicator" v-else>
            <LoadingSpinner />
        </div>
    </div>
</template>
  
<script>
import { defineComponent, ref, onMounted, computed } from 'vue';
import { useStore } from 'vuex';
import PublishButton from '@/components/ui/util/PublishButton.vue';
import PrimaryButton from '@/components/ui/util/PrimaryButton.vue';
import { getDestination } from '@/components/services/destinationService.js';
import LoadingSpinner from '@/components/ui/chatPage/LoadingSpinner.vue';

export default defineComponent({
  name: 'DestinationCard',
  components: {
    PrimaryButton,
    PublishButton,
    LoadingSpinner,
  },
  props: {
    destination: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const store = useStore();
    const imageUrl = ref(require('@/assets/popup/popupHero/hero_default.jpeg')); // Default image
    const isLoading = ref(true); // Loading state
    const destination = ref({}); // Destination details

    const loadDestinationCard = async () => {
      //console.log('Loading Destination Card:', props.destination.city, props.destination.country);

      try {
        const card = await getDestination(props.destination.city, props.destination.country, 1);
        //console.log('Destination Card:', card);

        destination.value = card;
        //console.log('Destination Card value:', destination.value);

        if (card.image_url) {
          const isValid = await isValidUrl(card.image_url);
          if (isValid) {
            imageUrl.value = card.image_url;
          } else {
            imageUrl.value = require('@/assets/popup/popupHero/hero_default.jpeg'); // Fallback image
          }
        }
      } catch (error) {
        console.error(`Error loading destination card - ${props.destination.city}, ${props.destination.country}:`, error);
      } finally {
        isLoading.value = false;
      }

      //console.log('Destination Card:', destination.value);

    };


    // Function to explore the destination
    const explore = async () => {
      try {
        const destinationDetails = await getDestination(props.destination.city, props.destination.country, 2);
        //console.log('Explore Destination Details:', destinationDetails);
        if (destinationDetails) {
          store.dispatch('updateDestination', destinationDetails);
          store.dispatch('updateView', 'destination');
        } else {
          console.error(`No destination details found - ${props.destination.city}, ${props.destination.country}`);
        }
      } catch (error) {
        console.error(`Error fetching destination details - ${props.destination.city}, ${props.destination.country}:`, error);
      }
    };

    // Get expense rating as dollar signs
    const getExpenseRating = (rating) => {
      return '$'.repeat(rating);
    };

    // Function to validate URL
    const isValidUrl = async (url) => {
      try {
        const response = await fetch(url, { method: 'HEAD' });
        return response.ok;
      } catch (error) {
        return false;
      }
    };

    onMounted(() => {
      loadDestinationCard();
    });

    // Computed properties
    const imageAlt = computed(() => destination.value?.image_desc ?? '');
    const city = computed(() => destination.value?.city ?? 'Unknown City');
    const country = computed(() => destination.value?.country ?? 'Unknown Country');
    const description = computed(() => destination.value?.description?.join(', ') ?? 'Description not available');
    const expenseRating = computed(() => destination.value?.expense_rating ? getExpenseRating(destination.value.expense_rating) : 'N/A');
    const averageTemperature = computed(() => destination.value?.average_temperature ? `${destination.value.average_temperature}°C Avg.` : 'Temperature not available');


    return {
      explore,
      imageUrl,
      isLoading,
      imageAlt,
      city,
      country,
      description,
      expenseRating,
      averageTemperature,
    };
  },

});
</script>
  
<style scoped>
.card-container {
    border: 1px solid var(--border-color);
    /* max-height: px; */
    width: var(--card-width);
    height: 390px;
    border-radius: 12px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.25);
    background-color: #fff;
    display: flex;
    flex-direction: column;
    margin-right: 8px;
    margin-top: 10px;
}

.card-header {
    position: relative;
    display: flex;
    align-items: center;
}

.card-bg-image {
    top:0;
    flex-shrink: 0;
    width: 100%;
    height: 212px;
    object-fit: cover;
}

.header-title {
    position: absolute;
    top: 10px;
    left: 10px;
    background-color: white;
    border: none;
    font-weight: bold;
    font-size: 1em;
    padding: 8px 12px 8px 8px;
    margin: 5px;
    border-radius: 36px;
    display: flex;
    align-items: center;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.4);
}

.title-icon {
  width: 16px;
  height: 16px;
  margin-right: 8px;
}

.card-body {
  padding: 10px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.card-body h3 {
    font-size: 1em;
    display: -webkit-box;
    margin: 0 0;
    -webkit-line-clamp: 2;  /* Limits the content to 2 lines */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis; /*Adds "..." at the end if the text overflows */
}

.card-body p {
    font-size: 0.85em;
    display: -webkit-box;
    margin: 0 0;
    -webkit-line-clamp: 2;  /* Limits the content to 2 lines */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis; /*Adds "..." at the end if the text overflows */
}

.card-footnote {
  display: flex;
  /* justify-content: space-between; */
  margin: 4px 0;
}

.card-footnote span {
    display: flex;
    align-items: center;
    font-size: 0.75em;
    margin: 0;
}

.card-footnote span:not(:first-child) {
    margin-left: 8px;
}

.footnote-icon {
  width: 16px;
  height: 16px;
  margin-right: 3px;
}


.call-to-action {
  display: flex;
  /* justify-content: space-between; */
  margin-top: 8px;
  margin-bottom: 5px;
}

.loading-indicator {
  text-align: center;
  padding: 20px;
  font-size: 1em;
}


</style>
  