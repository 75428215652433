<template>
    <button class="select-button" @click="handleClick">
      {{ text }}
    </button>
  </template>
  
  <script>
  export default {
    name: 'SecondarySelectButton',
    props: {
      text: {
        type: String,
        required: true,
      },
    },
    methods: {
      handleClick() {
        // console.log(this.text);
        this.$emit('clicked', this.text);
      },
    },
  };
  </script>
  
  <style scoped>
  .select-button {
    background-color: var(--white);
    font-size: 1.15em;
    color: var(--primary-color);
    border: 2px solid var(--primary-color);
    padding: 8px 16px;
    margin-right: 10px;
    margin-top: 8px;
    border-radius: 12px;
    display: inline-block;
  }
  
  .select-button:hover {
    background-color: var(--secondary-color);
    color: white;
  }

  </style>
  
  