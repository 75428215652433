<template>
    <div class="carousel-container">
        <button v-if="!isAtStart" @click="scrollLeft" class="scroll-button left">&lt;</button>
        <div class="carousel-slide" ref="carousel">
            <slot></slot>
        </div>
        <button v-if="!isAtEnd" @click="scrollRight" class="scroll-button right">&gt;</button>
    </div>
</template>
  
<script>
import  { throttle } from 'lodash';

  export default {
    name: 'CarouselSlide',
    data() {
      return {
        isAtStart: true,
        isAtEnd: false,
      };
    },
    mounted() {
      this.checkScrollPosition = throttle(this.checkScrollPosition, 200); // Throttle to 200ms
      this.$refs.carousel.addEventListener('scroll', this.checkScrollPosition);
      this.$nextTick(() => {
        setTimeout(() => {
          this.checkScrollPosition();
        }, 0);
      });
    },

    beforeUnmount() {
      this.$refs.carousel.removeEventListener('scroll', this.checkScrollPosition);
    },
    methods: {
      scrollLeft() {
        this.$refs.carousel.scrollBy({
          left: -this.$refs.carousel.clientWidth / 2,
          behavior: 'smooth'
        });
        this.$nextTick(() => {
          this.checkScrollPosition();
        });
      },
      scrollRight() {
        this.$refs.carousel.scrollBy({
          left: this.$refs.carousel.clientWidth / 2,
          behavior: 'smooth'
        });
        this.$nextTick(() => {
          this.checkScrollPosition();
        });
      },
      checkScrollPosition() {
        const carousel = this.$refs.carousel;
  
        if (!carousel || carousel.children.length === 0) {
          return;
        }
  
        const scrollLeft = carousel.scrollLeft;
        const maxScrollLeft = carousel.scrollWidth - carousel.clientWidth;
        const firstChild = carousel.children[0];
        const itemStyle = window.getComputedStyle(firstChild);
        const itemMargin = parseInt(itemStyle.marginRight) || 0;
  
        this.isAtStart = scrollLeft <= itemMargin;
       this.isAtEnd = carousel.scrollWidth <= carousel.clientWidth || scrollLeft >= (maxScrollLeft - itemMargin);


        //console.log(scrollLeft, carousel.clientWidth, carousel.scrollWidth, maxScrollLeft, itemMargin, this.isAtStart, this.isAtEnd);
      },
    },
  };
  </script>
  
  <style scoped>
  .carousel-container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow-x: hidden; /* Ensures that the content doesn't overflow horizontally */
  }
  
  .carousel-slide {
    display: flex;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    -webkit-overflow-scrolling: touch;
    width: 100%;
    padding: 10px 0px;
    margin: 0 8px;
  }
  
  .carousel-slide::-webkit-scrollbar {
    display: none;
  }
  
  .carousel-slide > * {
    /* flex: 0 0 var(--card-width); */
    flex: 0 0 auto; /* Allows the card to take up its natural width */
    scroll-snap-align: start; /* Aligns each card to the start of the scroll container */
    margin: 0 var(--carousel-gap); /* Space between cards */
  }
  
  .scroll-button {
    background-color: white;
    color: var(--primary-color);
    outline: none;
    border-radius: 50%;
    width: 48px;
    height: 48px;
    margin: 0 6px;
    border: none;
    cursor: pointer;
    position: absolute;
    top: 49%;
    transform: translateY(-50%);
    z-index: 10;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.9);
  }
  .scroll-button.left {
    left: 8px;
  }
  .scroll-button.right {
    right: 8px;
  }
  
  @media (max-width: 768px) {
    .scroll-button {
      width: 48px;
      height: 48px;
    }
    .scroll-button.left {
      left: 4px;
    }
    .scroll-button.right {
      right: 4px;
    }
  }
</style>
  