<template>
  <div class="icon-button">
    <!-- <button class="icon-button">
      <i :class="icon"></i>
    </button> -->
    <template v-if="click">
      <a href="javascript:void(0)" @click="click">
        <img :src="require('@/assets/chat/inputArea/' + icon)" :alt="altText" />
      </a>
    </template>
    <template v-else>
      <a :href="link">
        <img :src="require('@/assets/chat/inputArea/' + icon)" :alt="altText" />
      </a>
    </template>
  </div>
</template>
  
<script>
export default {
  name: 'InputAreaIcon',
  props: {
    icon: {
      type: String,
      required: true
    },
    altText: {
      type: String,
      default: '',
      required: false
    },
    link: {
      type: String,
      required: false
    },
    click: {
      type: Function,
      required: false

    },
  }
}
</script>

<style scoped>
.icon-button {
  background: none;
  border: none;
  border-radius: 8px;
  border: 1px solid transparent;
  outline: none;
  padding: 3px;
  cursor: pointer;
}

.icon-button img {
  width: 32px;
  height: 32px;
}

.icon-button:hover {
  border: 1px solid var(--border-color);
  /* background-color: var(--button-hover-color); */
}


  </style>
  