export const isWorkingUrl = async (url)  => {
    if (!url) {
        return false;
    }

    try {
      const response = await fetch(url, { method: 'GET' });
      //console.log(url, response);
      return response.ok;
    } catch (error) {
        console.error('Error checking URL:', error);
      return false;
    }

}


export const isEmpty = (value) => {
  if (value === null || value === undefined) {
      return true;
  }
  
  if (typeof value === 'string') {
      return value.trim().length === 0;
  }
  
  if (Array.isArray(value)) {
      return value.length === 0;
  }
  
  if (typeof value === 'object') {
      return Object.keys(value).length === 0 && value.constructor === Object;
  }
  
  return false; // For other types, return false if they are not empty by default
}


