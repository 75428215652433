import axios from 'axios';
import { BACKEND_API_URL } from '@/config.js';

export const searchHotels = async (lat, long) => {
    try {
        const url = BACKEND_API_URL + '/hotels/listbygeocode?lat=' + lat + '&long=' + long;

        const response = await axios.get(url , 
            {
                headers: {
                    'Content-Type': 'application/json',
                    // 'Authorization': 'Bearer token'
                },
                timeout: 3000
            }
        );
        console.log('Success:', response.data);
        return response.data.data;
        // stateThread.value = response.data;
    } catch (error) {
        console.error('Error:', error);
    }
}