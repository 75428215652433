<template>
  <div class="itinerary-container">
    <div class="itinerary-header">
      <h1>{{ itinerary.itinerary_title }}</h1>
      <div class="itinerary-highlight">
        <h2>Highlight</h2>
        <ul>
          <li><span><img src="@/assets/cityPage/pin.png" alt="Route" class="title-icon">{{ itinerary.route }}</span></li>
          <li><span><img src="@/assets/cityPage/calendar.png" alt="Date" class="title-icon">{{ itinerary.trip_duration }}</span></li>
          <li><span><img src="@/assets/cityPage/season.png" alt="Season" class="title-icon">{{ itinerary.available_months }}</span></li>
          <li><span><img src="@/assets/cityPage/people.png" alt="group size" class="title-icon">{{ itinerary.group_size }}</span></li>
        </ul>
      </div>
      <p>{{ itinerary.description }}</p>
    </div>
    
    <div class="trip-itinerary">
      <h2>Trip Itinerary</h2>
      <div class="carousel-container">
        <button class="carousel-button left" @click="scrollLeft" v-show="!isAtStart">&#10094;</button>

        <div class="day-tabs" @scroll="checkScroll">
          <div 
              v-for="(day, index) in itinerary.itinerary" 
              :key="index" 
              class="day-tab"
              :class="{ active: selectedDayIndex === index }"
              @click="selectDay(index)"
          >
            <span class="day-tab-title">{{ day.days }}</span>
            <span class="day-tab-city">{{ day.city }}</span>
            <img 
              :src="getIconSrc(index)" 
              alt="" 
              class="day-tab-icon" 
            />
          </div>  
        </div>
        <button class="carousel-button right" @click="scrollRight" v-show="!isAtEnd">&#10095;</button>

      </div>

      <GoogleMapView 
        v-if="selectedDay.coordinates" 
        :coordinates="selectedDay.coordinates" 
      />

      <div class="day-details" v-if="selectedDay">
        <h3><span><img src="@/assets/cityPage/details.png" alt="day details" class="header-icon">{{ selectedDay.days }}: {{ selectedDay.city }}</span></h3>
        <ul>
          <li v-for="(highlight, idx) in selectedDay.highlights" :key="idx">{{ highlight }}</li>
        </ul>
      </div>
    </div>
  </div>
</template>
  
  <script>
import GoogleMapView from '@/components/ui/cityPage/GoogleMapView.vue';

  export default {
    name: 'ItineraryView',
    components: {
      GoogleMapView,
    },
    props: {
      itinerary: {
        type: Object,
        required: true
      }
    },
    data() {
      return {
        selectedDayIndex: 0,
        isAtStart: true,
        isAtEnd: false,
        GoogleMapView,
      };
    },
    computed: {
      selectedDay() {
        return this.itinerary.itinerary[this.selectedDayIndex];
      },
    },
    methods: {
      scrollLeft() {
        const container = this.$el.querySelector('.day-tabs');
        container.scrollBy({ left: -200, behavior: 'smooth' });
      },
      scrollRight() {
        const container = this.$el.querySelector('.day-tabs');
        container.scrollBy({ left: 200, behavior: 'smooth' });
      },
      checkScroll() {
        const container = this.$el.querySelector('.day-tabs');
        this.isAtStart = container.scrollLeft === 0;
        this.isAtEnd = container.scrollWidth <= container.scrollLeft + container.clientWidth;
      },
      getIconSrc(index) {
        if (this.selectedDayIndex === index) {
          return index === 0 ? require('@/assets/cityPage/flight-takeoff-white.png') : require('@/assets/cityPage/camera-white.png');
        }
        return index === 0 ? require('@/assets/cityPage/flight-takeoff.png') : require('@/assets/cityPage/camera.png');
      },
      selectDay(index) {
        this.selectedDayIndex = index;
      },
    },
    mounted() {
      this.checkScroll();
    },
    updated() {
      this.checkScroll();
    },    
  }
  </script>
  
  <style scoped>
  .itinerary-container {
    font-size: .85rem;
    padding: 20px;
    margin: 0 auto;
  }
  
  .itinerary-header {
    margin-bottom: 20px;
  }

  .itinerary-highlight ul {
    list-style: none;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
  }

  .itinerary-highlight li {
    font-weight: 600;
    display: inline;
    margin-right: 16px;
    margin-bottom: 5px;
  }

  .itinerary-highlight span {
    white-space: nowrap;
  }

  .title-icon {
    width: 12px;
    height: 12px;
    margin-right: 3px;
  }

  .trip-itinerary {
    border-top: 1px solid var(--border-color);
    margin-top: 20px;
  }

  .carousel-container {
    position: relative;
    display: flex;
    align-items: center;
  }

  .trip-itinerary .day-plan {
    border-top: 1px solid var(--border-color);
    padding-top: 10px;
    margin-top: 10px;
  }

  .day-tabs {
  display: flex;
  overflow-x: auto;
  scroll-behavior: smooth;
  --webkit-overflow-scrolling: touch;
  padding: 10px 0;
  margin: 0 12px;
}

.day-tabs > * {
  flex: 0 0 13%;
  scroll-snap-align: start;
}

.day-tabs::-webkit-scrollbar {
  display: none; /* Hide scrollbar for better UX */
}

.day-tabs :hover {
  background-color: var(--primary-color);
  color: white;
}

.day-tab {
  position: relative;
  min-width: 80px;
  flex: 0 0 auto;
  padding: 10px;
  margin: 5px;
  text-align: left;
  border: 1px solid var(--border-color);
  border-radius: 5px;
  cursor: pointer;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s;
}

.day-tabs :hover {
  background-color: var(--secondary-color);
  color: white;
}

.day-tab.active {
  background-color: var(--primary-color);
  color: white;
}

.day-tab.active:hover {
  background-color: var(--primary-color);
  color: white;
}

.day-tab-title {
  font-weight: bold;
  display: block;
  margin-bottom: 5px;
  white-space: nowrap;
  pointer-events: none;  /* Prevents hover effects */
}

.day-tab-title:hover {
  background-color: transparent;
  color: inherit;
}

.day-tab-city:hover {
  background-color: transparent;
  color: inherit;
}


.day-tab-icon {
  position: absolute;
  top: 5px;
  right: 5px;
  width: 16px;
  height: 16px;
}

.carousel-button {
  background-color: white;
  color: var(--primary-color);
  outline: none;
  border-radius: 50%;
  /* box-sizing: border-box; */
  width: 25px;
  height: 25px;
  margin: 0;
  border: none;
  cursor: pointer;
  padding: 3px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.6);
}

.carousel-button:disabled {
  /* cursor: not-allowed; */
  opacity: 0.5;
  color: #ccc; /* Disabled button color */
}

.carousel-button.left {
  left: 0;
}

.carousel-button.right {
  right: 0;
}

.day-details {
  margin-top: 10px;
  border: 2px solid var(--border-color);
  border-radius: 12px;
  padding: 0 10px 10px 10px;
}

.day-details h3 span {
  display: flex;
  align-items: center;
  white-space: nowrap;
}

.day-details li {
    margin-bottom: 10px;
  }

.header-icon {
    width: 35px;
    height: 35px;
    margin-right: 10px;
  }
</style>
  