<template>
    <div class="dots-container" aria-live="polite">
        <div class="dots-message-box">
            <span class="typing">typing</span> 
            <div class="loading-dots">
                <div class="dot"></div>
                <div class="dot"></div>
                <div class="dot"></div>
                <div class="dot"></div>
                <div class="dot"></div>
            </div>
        </div>
    </div>
</template>
  
  <script>

  export default {
    name: 'LoadingDots'
  };
  </script>
  
<style scoped>
.dots-container {
  margin-bottom: 20px;
  padding: 10px 20px 0px 20px;
}

.dots-message-box {
    display: flex;
    background-color: white;
    border-radius: 16px;
    border-end-start-radius : 0;
    text-align: left;
    padding: 16px 16px;
    align-items: center;
    /* align-items: flex-end; */
    /* flex: 1; */
    max-width: 88%;

}

.typing {
    margin-right: 5px;
    font-size: 1.15em;
    font-style: italic;
    color: var(--text-color);
}

.loading-dots {
    display: flex;
    justify-content: left;
    align-items: center;
}

.dot {
  width: 3px;
  height: 3px;
  margin: 0 2px;
  background-color: var(--text-color); /* Adjust color as needed */
  /* align-self: center; */
  align-items: center;
  border-radius: 50%;
  animation: dotBounce 1.4s infinite ease-in-out both;
}

.dot:nth-child(1) {
  animation-delay: -0.32s;
}

.dot:nth-child(2) {
  animation-delay: -0.16s;
}

@keyframes dotBounce {
  0%, 80%, 100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
}
  </style>
  