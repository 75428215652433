<template>
    <button 
      :class="['select-button', { 'active': isActive }]"
      @click="handleClick"
    >
      {{ text }}
    </button>
  </template>
  
  <script>
  export default {
    name: 'PrimarySelectButton',
    props: {
      text: {
        type: String,
        required: true,
      },
      isActive: {
      type: Boolean,
      default: false
    }
    },
    methods: {
      handleClick() {
        // console.log(this.text);
        this.$emit('clicked', this.text);
      },
    },
  };
  </script>
  
  <style scoped>
  .select-button {
    background-color: var(--white);
    color: var(--primary-color);
    border: 2px solid var(--border-color);
    font-size: 1.15em;
    font-weight: bold;
    padding: 8px 16px;
    margin-right: 8px;
    margin-top: 8px;
    border-radius: 16px;
    display: inline-block;
  }
  
  .select-button.active {
    background-color: var(--primary-color);
    color: var(--white);
  }

  </style>
  