import { createApp } from 'vue'
import { createWebHistory, createRouter } from 'vue-router';  // Importing the router
import routes from '@/routes.js';  // Importing the routes
import '@/styles.css';  // Importing the global styles
import App from '@/App.vue'
import store from '@/store.js'; // Import the store

const app = createApp(App);

const router = createRouter({
    history: createWebHistory(),
    routes: routes,
})

// load store
app.use(store);

// load router
app.use(router);

// Finally mount the app
app.mount('#app');
