<template>
    <div :class="headerClasses">
      <img v-if="iconSrc" :src="iconSrc" :alt="altText" class="title-icon">
      <span>{{ title }}</span>
    </div>
</template>
  
  <script>
  export default {
    name: 'HeaderLevel2',
    props: {
      title: {
        type: String,
        required: true,
      },
      iconSrc: {
        type: String,
        default: '', // Default to an empty string
      },
      altText: {
        type: String,
        default: '', // Default to an empty string
      },
      hasBorderTop: {
        type: Boolean,
        default: true,
      },
    },
    computed: {
      headerClasses() {
        return {
          'header-container': true,
          'no-border-top': !this.hasBorderTop,
        }
      },
    },
  }
  </script>
  
  <style scoped>
  .header-container {
    font-weight: bold;
    font-size: 1.45em;
    border: none;
    border-top: 1px solid var(--border-color);
    padding: 12px 0px;
    margin: 5px 3px;
    display: flex;
    align-items: center;
  }

  .no-border-top {
    border-top: none;
  }
  
  .title-icon {
    width: 24px;
    height: 24px;
    margin-right: 10px;
  }
  </style>
  