<template>
  <div class="page-header">
      <ItineraryHeader />
  </div>
  <div class="page-body">
    <div class="itinerary-container">
      <div class="itinerary-header">
        <h1>{{ itinerary.itinerary_title }}</h1>
        <div class="itinerary-highlight">
          <h2>Highlight</h2>
          <ul>
            <li><span><img src="@/assets/cityPage/pin.png" alt="Route" class="title-icon">{{ itinerary.route }}</span></li>
            <li><span><img src="@/assets/cityPage/calendar.png" alt="Date" class="title-icon">{{ itinerary.trip_duration }}</span></li>
            <li><span><img src="@/assets/cityPage/season.png" alt="Season" class="title-icon">{{ itinerary.available_months }}</span></li>
            <li><span><img src="@/assets/cityPage/people.png" alt="group size" class="title-icon">{{ itinerary.group_size }}</span></li>
          </ul>
        </div> <!-- itinerary-highlight -->
        <p>{{ itinerary.description }}</p>
      </div> <!-- itinerary-header -->
      
      <div class="trip-itinerary">
        <h2>Trip Itinerary</h2>
        <div class="carousel-container">
          <button class="carousel-button left" @click="scrollLeft" v-show="!isAtStart">&#10094;</button>
  
          <div class="day-tabs" @scroll="checkScroll">
            <div 
                v-for="(day, index) in itinerary.itinerary" 
                :key="index" 
                class="day-tab"
                :class="{ active: selectedDayIndex === index }"
                @click="selectDay(index)" 
            >

              <span class="day-tab-title">{{ day.days }}</span>
              <span class="day-tab-city">{{ day.city }}</span>
              <img 
                :src="getIconSrc(index)" 
                alt="" 
                class="day-tab-icon" 
              />
            </div> <!-- day-tab -->
          </div> <!-- day-tabs --> 
        </div> <!-- carousel-container -->
        <button class="carousel-button right" @click="scrollRight" v-show="!isAtEnd">&#10095;</button>

      </div> <!-- trip-itinerary -->

      <GoogleMapView 
        v-if="selectedDay.coordinates" 
        :coordinates="selectedDay.coordinates" 
      />

      <div class="day-details" v-if="selectedDay">
        <h3><span><img src="@/assets/cityPage/details.png" alt="day details" class="header-icon">{{ selectedDay.days }}: {{ selectedDay.city }}</span></h3>
        <ul>
          <li v-for="(highlight, idx) in selectedDay.highlights" :key="idx">{{ highlight }}</li>
        </ul>
      </div> <!-- day-details -->
    </div> <!-- itinerary-container -->

    <!-- <div class="hotel-container">
      <div v-if="hotels" class="hotel-rows">
        <h1>Hotel</h1>
        <p>Please choose your preferred hotel from the following options to get the total price.</p>
        <div class="hotel-cards">
          <HotelCard v-for="hotel in hotels" :key="hotel.hotelId" :hotel="hotel" />
        </div>
      </div>  
    </div> -->

  </div> <!-- page-body -->
</template>
    

<script>
import { ref, computed, onMounted, onUpdated } from 'vue';
import GoogleMapView from '@/components/ui/cityPage/GoogleMapView.vue';
import { useStore } from 'vuex';
import { itinerary as defaultItinerary } from '@/data/itinerary.js';
import ItineraryHeader from './ItineraryHeader.vue';
// import { searchHotels } from '@/components/services/hotelService';
// import HotelCard from '@/components/ui/homePage/HotelCard.vue';

export default {
  name: 'ItineraryPage',
  components: {
    GoogleMapView,
    ItineraryHeader,
    // HotelCard,
  },
  setup() {
    const selectedDayIndex = ref(0);
    const isAtStart = ref(true);
    const isAtEnd = ref(false);

    const store = useStore();
    const itinerary = defaultItinerary;

    let selectedHotelId = null;
    // let hotels = ref([]);

    const selectedDay = computed(() => {
      return itinerary.itinerary[selectedDayIndex.value];
    });

    const scrollLeft = () => {
      const container = document.querySelector('.day-tabs');
      container.scrollBy({ left: -200, behavior: 'smooth' });
      checkScroll();
    };

    const scrollRight = () => {
      const container = document.querySelector('.day-tabs');
      container.scrollBy({ left: 200, behavior: 'smooth' });
      checkScroll();
    };

    const checkScroll = () => {
      const container = document.querySelector('.day-tabs');
      isAtStart.value = container.scrollLeft === 0;
      isAtEnd.value = container.scrollWidth <= container.scrollLeft + container.clientWidth;
    };

    const getIconSrc = (index) => {
      if (selectedDayIndex.value === index) {
        return index === 0 ? require('@/assets/cityPage/flight-takeoff-white.png') : require('@/assets/cityPage/camera-white.png');
      }
      return index === 0 ? require('@/assets/cityPage/flight-takeoff.png') : require('@/assets/cityPage/camera.png');
    };

    const selectDay = (index) => {
      selectedDayIndex.value = index;
    };

    const selectHotel = (hotelId) => {
      selectedHotelId.value = hotelId;
    }

    onMounted(async () => {
      // hotels.value = await searchHotels(35.0116, 135.7681);
      alert(`No data found for ${store.state.itinerary.itinerary_title}.  As a demo, Osaka's data is used as an example.`);
      checkScroll();
    });

    onUpdated(() => {
      checkScroll();
    });

    return {
      itinerary,
      selectedDayIndex,
      isAtStart,
      isAtEnd,
      selectedDay,
      scrollLeft,
      scrollRight,
      checkScroll,
      getIconSrc,
      selectDay,      
      selectHotel,
    };
  },
};
</script>


    
  
<style scoped>
.page-header {
    display: flex;
    min-height: 190px;
    justify-content: space-between;
    align-items: center;
    font-weight: bold;
}

.page-body {
    flex: 1;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    border-radius: 0 0 20px 20px;
}

.hotel-container {
  /* font-size: 0.9rem; */
  padding: 20px;
  margin: 0 auto;
}

.hotel-rows {
  border-top: 1px solid var(--border-color);
  margin-top: 20px;
}

.hotel-cards {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}


.itinerary-container {
  font-size: .85rem;
  padding: 20px;
  margin: 0 auto;
}

.itinerary-header {
  margin-bottom: 20px;
}

.itinerary-highlight ul {
  list-style: none;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
}

.itinerary-highlight li {
  font-weight: 600;
  display: inline;
  margin-right: 16px;
  margin-bottom: 5px;
}

.itinerary-highlight span {
  white-space: nowrap;
}

.title-icon {
  width: 12px;
  height: 12px;
  margin-right: 3px;
}

.trip-itinerary {
  border-top: 1px solid var(--border-color);
  margin-top: 20px;
}

.carousel-container {
  position: relative;
  display: flex;
  align-items: center;
}

.trip-itinerary .day-plan {
  border-top: 1px solid var(--border-color);
  padding-top: 10px;
  margin-top: 10px;
}

.day-tabs {
  display: flex;
  overflow-x: auto;
  scroll-behavior: smooth;
  --webkit-overflow-scrolling: touch;
  padding: 10px 0;
  margin: 0 12px;
}

.day-tabs > * {
  flex: 0 0 13%;
  scroll-snap-align: start;
}

.day-tabs::-webkit-scrollbar {
  display: none; /* Hide scrollbar for better UX */
}

.day-tabs :hover {
  background-color: var(--primary-color);
  color: white;
}

.day-tab {
  position: relative;
  min-width: 80px;
  flex: 0 0 auto;
  padding: 10px;
  margin: 5px;
  text-align: left;
  border: 1px solid var(--border-color);
  border-radius: 5px;
  cursor: pointer;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s;
}

.day-tabs :hover {
  background-color: var(--secondary-color);
  color: white;
}

.day-tab.active {
  background-color: var(--primary-color);
  color: white;
}

.day-tab.active:hover {
  background-color: var(--primary-color);
  color: white;
}

.day-tab-title {
  font-weight: bold;
  display: block;
  margin-bottom: 5px;
  white-space: nowrap;
  pointer-events: none;  /* Prevents hover effects */
}

.day-tab-title:hover {
  background-color: transparent;
  color: inherit;
}

.day-tab-city:hover {
  background-color: transparent;
  color: inherit;
}


.day-tab-icon {
  position: absolute;
  top: 5px;
  right: 5px;
  width: 16px;
  height: 16px;
}

.carousel-button {
  background-color: white;
  color: var(--primary-color);
  outline: none;
  border-radius: 50%;
  /* box-sizing: border-box; */
  width: 25px;
  height: 25px;
  margin: 0;
  border: none;
  cursor: pointer;
  padding: 3px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.6);
}

.carousel-button:disabled {
  /* cursor: not-allowed; */
  opacity: 0.5;
  color: #ccc; /* Disabled button color */
}

.carousel-button.left {
  left: 0;
}

.carousel-button.right {
  right: 0;
}

.day-details {
  margin-top: 10px;
  border: 2px solid var(--border-color);
  border-radius: 12px;
  padding: 0 10px 10px 10px;
}

.day-details h3 span {
  display: flex;
  align-items: center;
  white-space: nowrap;
}

.day-details li {
    margin-bottom: 10px;
  }

.header-icon {
    width: 35px;
    height: 35px;
    margin-right: 10px;
  }
</style>
  