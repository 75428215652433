<template>
    <div class="photo-card" @click="selectPhoto">
        <img :src="photo" alt="destination photo" @error="handleImageError" />
    </div>
</template>

<script>
export default {
    name: 'PhotoCard',
    props: {
        photo: {
            type: String,
            required: true
        }
    },
    methods: {
        selectPhoto() {
            this.$emit('photo-selected', this.photo);
        },
        handleImageError(event) {
            event.target.src = require('@/assets/popup/popupHero/hero_default.jpeg'); // Path to your default image
        },
    },
};
</script>

<style scoped>

.photo-card {
    width: 160px;
    height: 90px;
    border-radius: 12px;
    border: 0;
    overflow: hidden;
    /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.25);
    background-color: #fff; */
    display: flex;
    flex-direction: column;
}

.photo-card img {
    width:100%;
    height: 100%;
    object-fit: cover; /* Ensures the image covers the entire area without distortion */
}

</style>