<template>
    <div class="card-container">
        <div class="card-header">
            <img :src="itinerary.image_url" :alt="itinerary.image_desc" class="card-bg-image" />
            <div class="header-title">
                <img src="@/assets/itineraries/itinerary_icon.png" alt="Itinerary" class="title-icon">
                <span>Itinerary</span>
            </div>
        </div>
        <div class="card-body">
            <h3>{{ itinerary.itinerary_title }}</h3>
            <div class="card-footnote">
                <span>
                    <img src="@/assets/itineraries/location_icon.png" alt="Location" class="footnote-icon">
                    {{ itinerary.route }}
                </span>
                <span>    
                    <img src="@/assets/itineraries/stay_icon.png" alt="Duration" class="footnote-icon">
                    {{ itinerary.trip_duration }}
                </span>
                <span>    
                    <img src="@/assets/itineraries/season_icon.png" alt="Season" class="footnote-icon">
                    {{ itinerary.available_months }}
                </span>
            </div>
            <div class="call-to-action">
                <PrimaryButton @click="explore" buttonText="explore" />
                <PublishButton :initialState="false" />
            </div>
        </div>

    </div>
</template>
  
<script>
import { defineComponent } from 'vue';
import { useStore } from 'vuex';
import PublishButton from '@/components/ui/util/PublishButton.vue';
import PrimaryButton from '@/components/ui/util/PrimaryButton.vue';
// import { useRouter } from 'vue-router';

export default defineComponent({
  name: 'itineraryCard',
  components: {
    PrimaryButton,
    PublishButton
  },
  props: {
    itinerary: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    const store = useStore();
    //const router = useRouter();
    // console.log('Itinerary card props:', props.itinerary);

    const explore = () => {
      store.dispatch('updateItinerary', props.itinerary);
      store.dispatch('updateView', 'itinerary');
      // alert(`Explore itinerary: ${props.itinerary.itinerary_title}`);
    };


    return {
      explore,
    };
  }
});
</script>
  
<style scoped>
.card-container {
    border: 1px solid var(--border-color);
    /* max-height: 250px; */
    width: var(--card-width);
    height: 394px;
    border-radius: 12px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.25);
    background-color: #fff;
    display: flex;
    flex-direction: column;
}

.card-header {
    position: relative;
    display: flex;
    align-items: center;
}

.card-bg-image {
    top:0;
    flex-shrink: 0;
    width: 100%;
    height: 212px;
    object-fit: cover;
}

.header-title {
    position: absolute;
    top: 10px;
    left: 10px;
    background-color: white;
    border: none;
    font-weight: bold;
    font-size: 1em;
    padding: 8px 12px 8px 8px;
    margin: 5px;
    border-radius: 36px;
    cursor: pointer;
    display: flex;
    align-items: center;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.4);
}

.title-icon {
  width: 16px;
  height: 16px;
  margin-right: 8px;
}

.card-body {
  padding: 10px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.card-body h3 {
    font-size: 1em;
    display: -webkit-box;
    margin: 0 0;
    -webkit-line-clamp: 2;  /* Limits the content to 2 lines */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis; /*Adds "..." at the end if the text overflows */
}

.card-body p {
    font-size: 0.85em;
    display: -webkit-box;
    margin: 0 0;
    -webkit-line-clamp: 2;  /* Limits the content to 2 lines */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis; /*Adds "..." at the end if the text overflows */
}

.card-footnote {]
    display: block;
    margin: 2px 0;
}

.card-footnote span {
    display: inline-flex; /* Use flexbox to align items vertically */
    align-items: center; /* Vertically center the icon and text */
    font-size: 0.85em;
    font-weight: normal;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; /* Adds "..." at the end if the text overflows */
    margin: 1px 0;
    width: 100%;
}

.footnote-icon {
  width: 16px;
  height: 16px;
  margin-right: 5px;
  flex-shrink: 0; /* Prevent the icon from shrinking */
}


.call-to-action {
  display: flex;
  /* justify-content: space-between; */
  margin-top: 8px;
  margin-bottom: 5px;
}


.call-to-action-icon {
  width: 16px;
  height: 16px;
  margin-right: 5px;
}

.cta-button {
  /* margin: 10px 0; */
  background-color: var(--primary-color);
  color: white;
  border: none;
  border-radius: 33px;
  padding: 8px 12px;
  font-size: 0.9em;
  font-weight: bold;
  text-transform: uppercase;
  cursor: pointer;
}

.cta-button:hover {
  background-color: var(--secondary-color);
  color: black;
}


</style>
  