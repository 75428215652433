<template>
  <div>
    <div v-if="hasDestinations" class="destination-container">
      <HeaderLevel2
        title="Popular Destination" 
        :iconSrc="require('@/assets/icons/destination.png')" 
        altText="Popular Destination"
        :hasBorderTop="false"
      />
      <CarouselSlide>
        <DestinationCard v-for="destination in randomizedDestinations" :key="destination.city" :destination="destination" />
      </CarouselSlide>
    </div>
  </div>  
</template>

<script>
import HeaderLevel2 from '@/components/ui/util/HeaderLevel2.vue';
import CarouselSlide from '@/components/ui/util/CarouselSlide.vue';
import DestinationCard from './DestinationCard.vue';
// import { getDestinations } from '@/components/services/destinationService';
import { destinations } from '@/data/destinations.js';

export default {
  name: 'PopularDestinations',
  components: {
    HeaderLevel2,
    CarouselSlide,
    DestinationCard,
  },

  data() {
    return {
      destinations: destinations,
    };   
  },

  computed: {
    hasDestinations() {
      return Object.keys(this.destinations).length > 0;
    },

    randomizedDestinations() {
      const maxDestinations = 18;
      const destinationArray = Object.values(this.destinations);

      // Shuffle the array
      for (let i = destinationArray.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [destinationArray[i], destinationArray[j]] = [destinationArray[j], destinationArray[i]];
      }

      // Return only the first 12 destinations (or fewer if there are not 12 available)
      return destinationArray.slice(0, maxDestinations);
    }
  },

  mounted() {
    //console.log('PopularDestinations mounted: ', this.destinations);
  }

};
</script>

<style scoped>
.destination-container {
  margin: 20px 5px;
}


</style>