<template>
  <div class="page-header">
      <CityHeader />
  </div>
  <div class="page-body">
    <div  class="itineraries-container">
      <div v-for="(itinerary, index) in cityItineraries" :key="index">
        <ItineraryView :itinerary="itinerary" />
      </div>
    </div>

    <div class="flight-container">
      <div class="flight-rows">
        <h1>Flights</h1>
        <p>
          Based on the information you provided, your departure city is
          <strong>Toronto, Canada</strong>. If you need to make any changes, please
          let me know.
        </p>
        <p>
          Please choose your preferred flight from the following options to get the
          total price.
        </p>
        <FlightCard />
        <FlightCard2 />

      </div> 
    </div>


    <div class="hotel-container">
      <div v-if="hotels" class="hotel-rows">
        <h1>Hotel</h1>
        <p>Please choose your preferred hotel from the following options to get the total price.</p>
        <div class="hotel-cards">
          <HotelCard v-for="hotel in hotels" :key="hotel.hotelId" :hotel="hotel" />
        </div>
      </div>  
    </div>



  </div> 
</template>



<script>
import { defineComponent, ref, onMounted, computed } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import CityHeader from './CityHeader.vue';
import ItineraryView from './ItineraryView.vue';
import { itineraries } from '@/data/itineraries'; 
// import hotels from '@/data/hotels';
import { searchHotels } from '@/components/services/hotelService';
import HotelCard from '@/components/ui/homePage/HotelCard.vue';
import FlightCard from './FlightCard.vue';
import FlightCard2 from './FlightCard2.vue';

export default defineComponent({
  name: 'CityPage',
  components: {
    CityHeader,
    ItineraryView,
    FlightCard,
    FlightCard2,
    HotelCard,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const city = computed(() => store.state.city);
    let cityItineraries = ref([]);
    let selectedHotelId = null;
    let hotels = ref([]);

    const getItineraries = (cityName) => {
      if (Array.isArray(itineraries)) {
        const filteredItineraries = itineraries.filter(itinerary => itinerary.city === cityName);
        return (filteredItineraries.length > 0) ? filteredItineraries : [];
      } else {
        return [];
      } 
    };

    const selectHotel = (hotelId) => {
      selectedHotelId.value = hotelId;
    }

    const loadData = async () => {
      cityItineraries.value = getItineraries(city.value.city);
      hotels.value = await searchHotels(city.value.coordinates.lat, city.value.coordinates.lng);
      // console.log(hotels.value);
    };

    onMounted(() => {

      if (!city.value) {
        //console.log('CityPage mounted. No city selected', city.value);
        router.push('/');
      } else {    
        loadData();
        // console.log('CityPage mounted'. hotels);
      }
    });

    return {
      city,
      cityItineraries,
      hotels,
      selectedHotelId,
      selectHotel,
    };
  }
});
</script>

<style>


.page-header {
    display: flex;
    min-height: 190px;
    justify-content: space-between;
    align-items: center;
    font-weight: bold;
}

.page-body {
    flex: 1;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    border-radius: 0 0 20px 20px;
}

.hotel-container {
  /* font-size: 0.9rem; */
  padding: 20px;
  margin: 0 auto;
}

.hotel-rows {
  border-top: 1px solid var(--border-color);
  margin-top: 20px;
}

.hotel-cards {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}


.flight-container {
  /* font-size: 0.9rem; */
  padding: 20px;
  margin: 0 auto;
}

.flight-rows {
  border-top: 1px solid var(--border-color);
  margin-top: 20px;
}

</style>
