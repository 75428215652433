<template>
    <div class="flight-card">
      <h1>Flight</h1>
      <p class="info-text">
        <span class="icon">ℹ️</span> Based on the information you provided, your departure city is <strong>Toronto, Canada</strong>. If you
        need to make any changes, please let me know.
      </p>
      <p class="info-text">
        <span class="icon">ℹ️</span> Please choose your preferred flight from the following options to get the total price.
      </p>
      <div class="flight-info">
        <div class="flight-header">
          <span class="flight-label">Flight Info</span>
        </div>
        <div class="flight-details">
          <div class="departure">
            <h3>Departure <span class="date">July 1, 2024</span></h3>
            <p>Toronto Pearson International Airport Terminal 1</p>
            <div class="flight-type">
              <span class="icon">✈️</span> 1 stop
              <span class="icon">💺</span> Economy Class
            </div>
            <div class="route">
              <div class="city">
                <span class="airport">YYZ</span>
                <span class="date">July 1, 2024</span>
              </div>
              <div class="duration">
                <span>5h 26m</span>
                <div class="flight-line">
                  <hr />
                  <span class="plane">✈️</span>
                </div>
                <span>#1 flight</span>
              </div>
              <div class="city">
                <span class="airport">YVR</span>
                <span class="date">July 1, 2024</span>
              </div>
              <div class="duration">
                <span>10h 00m</span>
                <div class="flight-line">
                  <hr />
                  <span class="plane">✈️</span>
                </div>
                <span>#2 flight</span>
              </div>
              <div class="city">
                <span class="airport">NRT</span>
                <span class="date">July 1, 2024</span>
              </div>
            </div>
          </div>
          <div class="return">
            <h3>Return <span class="date">July 17, 2024</span></h3>
            <p>Narita International Airport Terminal 2</p>
            <div class="flight-type">
              <span class="icon">✈️</span> 1 stop
              <span class="icon">💺</span> Economy Class
            </div>
            <div class="route">
              <div class="city">
                <span class="airport">NRT</span>
                <span class="date">July 17, 2024</span>
              </div>
              <div class="duration">
                <span>5h 26m</span>
                <div class="flight-line">
                  <hr />
                  <span class="plane">✈️</span>
                </div>
                <span>#1 flight</span>
              </div>
              <div class="city">
                <span class="airport">YVR</span>
                <span class="date">July 17, 2024</span>
              </div>
              <div class="duration">
                <span>10h 00m</span>
                <div class="flight-line">
                  <hr />
                  <span class="plane">✈️</span>
                </div>
                <span>#2 flight</span>
              </div>
              <div class="city">
                <span class="airport">YYZ</span>
                <span class="date">July 17, 2024</span>
              </div>
            </div>
          </div>
          <div class="price">
            <h3>US$1,000.00</h3>
            <p>Valid until October 1, 2024</p>
          </div>
          <button class="select-button">Selected</button>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'FlightCard2'
  }
  </script>
  
  <style scoped>
.flight-card {
  font-family: Arial, sans-serif;
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  color: #333;
}

h1 {
  font-size: 24px;
  margin-bottom: 20px;
  color: #000;
}

.info-text {
  margin-bottom: 10px;
}

.icon {
  margin-right: 5px;
}

.flight-info {
  border: 2px solid #7ee2d9;
  border-radius: 8px;
  overflow: hidden;
}

.flight-header {
  background-color: #e6f7f5;
  padding: 10px;
}

.flight-label {
  background-color: #3498db;
  color: white;
  padding: 5px 10px;
  border-radius: 3px;
}

.flight-details {
  padding: 20px;
}

.departure, .return {
  margin-bottom: 20px;
}

h3 {
  color: #000;
}

.date {
  color: #3a7f3a;
  font-weight: normal;
}

.flight-type {
  margin: 10px 0;
  color: #3a7f3a;
}

.route {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.city {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.airport {
  font-weight: bold;
  color: #3a7f3a;
}

.duration {
  text-align: center;
  color: #666;
}

.flight-line {
  position: relative;
  width: 100px;
}

.flight-line hr {
  border: none;
  border-top: 2px solid #ccc;
}

.plane {
  position: absolute;
  top: -10px;
  left: 50%;
  transform: translateX(-50%);
}

.price {
  text-align: right;
  margin-top: 20px;
}

.price h3 {
  color: #000;
}

.select-button {
  background-color: #7ee2d9;
  color: #000;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  float: right;
  font-weight: bold;
}
</style>