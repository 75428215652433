<template>
    <div class="chat-window" id="chat-window" ref="chatWindow">
        <div class="message" v-for="message in stateMessages" :key="message.id">
            <div class="message-container" ref="messageContainer" :class="{'user': message.user, 'bot': !message.user}">
                <!-- <img v-if="!message.user" :src="getBotAvatar()" alt="User Avatar" class="avatar"> -->
                <div class="message-content" v-html="message.content.message"></div>
                <!-- <img v-if="message.user" :src="getUserAvatar()" alt="Bot Avatar" class="avatar"> -->
                <div v-if="message.content.destination_cards && message.content.destination_cards.length > 0" class="destination-container">
                  <CarouselSlide>
                    <DestinationCard v-for="destination in message.content.destination_cards" :key="destination.city" :destination="destination" />
                  </CarouselSlide>
                </div> 
                <div v-else-if="message.content.keywords && message.content.keywords.length > 0" class="keywords-container">
                  <SecondarySelectButton
                      v-for="(keyword, index) in message.content.keywords"
                      :key="`${keyword}-${index}`"
                      :text="keyword"
                      @clicked="submitMessage(keyword)"
                  />   
                </div> 
            </div>
        </div>
    </div>
</template>


<script>
import { watch, computed, onMounted, ref, nextTick } from 'vue';
import { useStore } from 'vuex';
import { initMessages } from '@/components/services/MessageService.js';
import DestinationCard from '@/components/ui/homePage/DestinationCard.vue'; 
import CarouselSlide from '@/components/ui/util/CarouselSlide.vue';
import SecondarySelectButton from '@/components/ui/util/SecondarySelectButton.vue';

export default {
  name: 'ChatWindow',
  components: {
    DestinationCard,
    CarouselSlide,
    SecondarySelectButton,
  },
  setup(props, { emit }) {
    const store = useStore();
    const stateMessages = computed(() => store.state.messages);

    const chatWindow = ref(null);

    // Ensure messages are initialized if empty
    onMounted(() => {
      if (!stateMessages.value || stateMessages.value.length === 0) {
        initMessages();
      }
      scrollToLast();
    });

    const getUserAvatar = () => {
      const randomNumber = 33;
      const userAvatar = `https://xsgames.co/randomusers/avatar.php?g=female&img=${randomNumber}`;
      return userAvatar;
    };

    const getBotAvatar = () => {
      const botAvatar = require('@/assets/chat/openai.png');
      return botAvatar;
    };

    const scrollToLast = () => {
      nextTick(() => {
        if (chatWindow.value) {
          const lastChildElement = chatWindow.value.lastElementChild;
          lastChildElement?.scrollIntoView({ behavior: 'smooth' });
        }

        // const chatWindow = document.querySelector('.chat-window');
        // if (chatWindow) {
        //   const lastChildElement = chatWindow.lastElementChild;
        //   lastChildElement?.scrollIntoView({ behavior: 'smooth' });
        // }
      });
    };

    const submitMessage = (txt) => {
      emit('message-submitted', {
        content: { message: txt },
        user: true, // Assuming the message is from the user
      });
    };

    watch(stateMessages, () => {
        scrollToLast();
        //console.log('stateMessages', stateMessages.value);
    }, { deep: true });

    return {
      stateMessages,
      getUserAvatar,
      getBotAvatar,
      submitMessage,
      chatWindow,
    };
  },
};
</script>


  
<style scoped>
.chat-window {
  /*flex-growth: 1; /* Allow the chat window to grow and take available space */
  /* margin-left: 60px; */
  padding: 10px 16px 0px 16px;
  max-width: 100%;
  box-sizing: border-box;
  overflow-x: hidden;
}

.message {
  margin-bottom: 10px;
}

.message .user {
  justify-content: flex-end;
}
 
.message .bot {
  justify-content: flex-start;

}

.message-container {
  display: flex;
  flex-direction: column;  /* Ensure the content and keywords are stacked vertically */
  align-items: flex-start;
  margin-bottom: 20px;
}

/* .message-container .bot {
  flex-direction: column;
} */

.user .avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-left: 10px;
}

.bot .avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}

.message-content {
  /* flex: 1; */
  max-width: 88%;
  font-size: 1.15em;
  padding: 16px 16px;
  word-wrap: break-word;

}

.user .message-content {
  /* background-color: #cbdded; */
  background-color: var(--primary-color);
  color: white;
  border-radius: 16px;
  border-end-end-radius : 0;
  text-align: right;
  align-self: flex-end;
}

.bot .message-content {
  background-color: white;
  border-radius: 16px;
  border-end-start-radius: 0;
  text-align: left;
  /* align-self: flex-end; */
}

.destination-container {
  margin: 20px 0px;
  width: 100%;
  overflow-x: hidden;
}

.keywords-container {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  margin-top: 4px;
}

  </style>
  