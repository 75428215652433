<template>
    <button class="close-button" @click="closePopup">x</button>
  </template>
  
  <script>
  import { useStore } from 'vuex';
  
  export default {
    name: 'ClosePopupButton',
    setup() {
      const store = useStore();
  
      const closePopup = async () => {
        await store.dispatch('updatePopupVisible', false);
        //console.log('Popup is closing', store.state.isPopupVisible, store.state.isMobile);
      };
  
      return {
        closePopup,
      };
    },
  };
  </script>
  
  <style scoped>
  .close-button {
    background-color: white;
    outline: none;
    border-radius: 50%;
    border: none;
    width: 35px;
    height: 35px;
    margin: 0 6px;
    display: none;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
  }
  
  /* Responsive design for mobile */
  @media (max-width: 768px) {
    .close-button {
      display: block;
    }
  }
  </style>
  