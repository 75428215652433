<template>
    <div class="menu-bar-wrapper">
        <div class="menu-bar" @mouseover="isHovered = true" @mouseleave="isHovered = false">
            <div class="icon nohover">
                <img src="@/assets/icons/app_menu_icon.png" alt="App Menu" />
            </div> 

            <div v-if="!isMenuOpen && isHovered" class="open-button" @click="toggleMenu">
                <div class="toggle-icon">
                    <img src="@/assets/icons/expand_icon.png" alt="Open" />
                </div> 
            </div>

            <div class="menu-display" v-if="isMenuOpen">
                <div class="separator"></div> 
                
                <div class="icon">
                    <img src="@/assets/icons/chat_icon.png" alt="Chat" />
                </div>  
                

                <div class="icon">
                    <img src="@/assets/icons/chat_history_icon.png" alt="Chat History" />
                </div>  

                <div class="icon">
                    <img src="@/assets/icons/wishlists_icon.png" alt="Wish List" />
                </div>  

                <div class="separator"></div>

                <div class="icon">
                    <img src="@/assets/icons/login_icon.png" alt="Login /  Profile"  />
                </div>

                <div class="icon hidden" >
                    <img src="@/assets/icons/app_icon.png" alt="Download App"  />
                </div>  

                <div class="toggle-icon" @click="toggleMenu">
                    <img src="@/assets/icons/collapse_icon.png" alt="Close"  />
                </div>
            </div>

        </div>
    </div>
</template>
  
  <script>
  export default {
    name: 'MenuBar',
    data() {
        return {
            isMenuOpen: false,
            isHovered: false,
        };
    },
    methods: {
        toggleMenu() {
            this.isMenuOpen = !this.isMenuOpen;
            if (!this.isMenuOpen) {
                this.isHovered = false; // Hide the open button after closing the menu
            }
        }
    },    
  }
  </script>
  
<style scoped>
.menu-bar-wrapper {
    position: relative;
    display: inline-block; /* Adjust to match your layout */
}

.menu-bar {
    display: flex; /* Ensures that the icons are aligned horizontally */
    align-items: center; /* Vertically center the icons */
    justify-content: space-between; /* Space out the icons */
    background-color: var(--white);
    border-radius: 12px;
    border: 1px solid var(--border-color);
}
  
.menu-display {
    display: flex; /* Ensures that the icons are aligned horizontally */
    align-items: center; /* Vertically center the icons */
    justify-content: space-between; /* Space out the icons */
}

.icon,
.toggle-icon {
    display: flex; /* Use Flexbox to align the image */
    align-items: center; /* Vertically center the image */
    justify-content: center; /* Horizontally center the image */    
    width: 36px;
    height: 36px;
    margin: 8px 4px;
    border-radius: 8px;
    border: 1px solid transparent;
}

.icon img {
    width: 24px;
    height: 24px;
    cursor: pointer;
}

.toggle-icon img {
    width: 6px;
    height: 20px;
    cursor: pointer;
}

.icon:hover:not(.nohover),
.toggle-icon:hover {
    border: 1px solid var(--border-color);
    /* background-color: var(--button-hover-color); */
}


.separator {
    width: 1px;
    height: 36px;
    margin: 0 8px;
    background-color: var(--border-color);
}

.hidden {
    display: none;
}
</style>
  