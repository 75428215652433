const dotenv = require('dotenv');
dotenv.config();

const env = process.env;

// rest-api url
const BACKEND_API_URL = env.VUE_APP_BACKEND_API_URL;
const GOOGLE_MAPS_API_KEY = env.VUE_APP_GOOGLE_MAPS_API_KEY;

// const { origin } = window.location;
// const origin = "https://ttg.firstmax.ca";

// export const BACKEND_API_URL = origin + '/api';

// // export const BACKEND_API_URL = "http://10.36.50.253:4000";
// console.log('BACKEND_API_URL:', BACKEND_API_URL);
// console.log('env:', env);

module.exports = {
    BACKEND_API_URL,
    GOOGLE_MAPS_API_KEY,
}