import { computed } from 'vue';
import axios from 'axios';
import { stateThread } from '@/states/threads.js';
import { isLoading } from '@/states/isLoading.js';
import { isEmpty } from './util.js';
import { BACKEND_API_URL } from '../../config.js';
import store from '@/store.js';

const greetings = { id: 0, content: { message: 'Here we are again, what are we chatting about today?  Ask me literally anything related to travel.' }, user: false };

const stateMessages = computed(() => store.state.messages);

export const initMessages = () => {
    greetings.content.message = convertTextToHTML(greetings.content.message);
    store.dispatch('updateMessage', greetings);
};

export const addUserMessage = async (newMessage) => {
    //console.log(addUserMessage, newMessage);
    addMessage(newMessage);

    isLoading.value = true;

    try {
        const run = await postMessage(newMessage);
        // console.log('after postMessage:', run);
        addAssistantMessage(run);
    } catch (error) {
        console.error('Error postMessage', error);
    } finally {
        isLoading.value = false;
    }

};

const addAssistantMessage = async (run) => {
    // console.log('addAssistantMessage', run);

    if (!run || !run.data || !run.data.content) {
        console.error('Invalid run object:', run);
        return;
    }

    for (const content of run.data.content) {
        //console.log('content:', JSON.stringify(content));
        let newMsgResp = [];

        for (const response of content.content) {
            const textValue = JSON.parse(response.text.value);
            //console.log('textValue:', JSON.stringify(textValue));

            if (textValue.message && !isEmpty(textValue.message)) {
                //console.log('textValue.message:', textValue.message);
                newMsgResp.message = convertTextToHTML(textValue.message);
            }

            if (textValue.keywords && !isEmpty(textValue.keyowrds)) {
                //console.log('textValue.keywords:', textValue.keywords);
                newMsgResp.keywords = textValue.keywords;
            }

            if (textValue.destination_cards && !isEmpty(textValue.destination_cards)) {
               //console.log('textValue.destination_cards:', textValue.destination_cards);
                newMsgResp.destination_cards = textValue.destination_cards;
            }

            if (textValue.destination_details && !isEmpty(textValue.destination_details)) {
                //console.log('textValue.destination_details:', textValue.destination_details);
                newMsgResp.destination_details = textValue.destination_details;
                // store.dispatch('updateDestination', newMsgResp.destination_details);
                // store.dispatch('updateView', 'destination');
            }

            if (textValue.itinerary_cards && !isEmpty(textValue.itinerary_cards)) {
                //console.log('textValue.itinerary_cards:', textValue.itinerary_cards);
                newMsgResp.itinerary_cards = textValue.itinerary_cards;
            }

            if (textValue.itinerary_details && !isEmpty(textValue.itinerary_details)) {
                //console.log('textValue.itinerary_details:', textValue.itinerary_details);
                newMsgResp.itinerary_details = textValue.itinerary_details;
            }

            const newMessage = { content: newMsgResp, user: false };
            addMessage(newMessage);
        }
    }
};

const addMessage = (newMessage) => {
    //newMessage = extractKeywords(newMessage);

    newMessage.id = stateMessages.value.length;
    store.dispatch('updateMessage', newMessage);
};

const postMessage = async (newMessage) => {
    try {
        const responses = await axios.post(BACKEND_API_URL + '/ask/',
            {
                threadId: stateThread.value.id,
                role: newMessage.user ? 'user' : 'assistant',
                content: newMessage.content.message
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                    // 'Authorization': 'Bearer token'
                },
                // timeout: 1000
            }
        );
        //console.log('Success:', responses.data);

        // store thread Id if it is not set
        if (responses.data || responses.data.content || responses.data.content[0] || responses.data.content[0].thread_id) {
            stateThread.value.id = responses.data.content[0].thread_id;
        }

        return responses;
    } catch (error) {
        console.error('Error:', error);
    }
};

const convertTextToHTML = (txt) => {
    // Output variable
    let out = '';
    // Split text at the newline character into an array
    const txt_array = txt.split("\n");
    // Get the number of lines in the array
    const txt_array_length = txt_array.length;
    // Variable to keep track of the (non-blank) line number
    let non_blank_line_count = 0;

    for (let i = 0; i < txt_array_length; i++) {
        // Get the current line
        let line = txt_array[i];

        // Continue if a line contains no text characters
        if (line === '') {
            continue;
        }

        line = line.replace(/\*\*(.*?)\*\*/g, '<b>$1</b>');

        non_blank_line_count++;
        // If a line is the first line that contains text
        if (non_blank_line_count === 1) {
            // ...wrap the line of text in a Heading 1 tag
            //out += `<h1>${line}</h1>`;
            out += `<p>${line}</p>`;
            // ...otherwise, wrap the line of text in a Paragraph tag.
        } else {
            out += `<p>${line}</p>`;
        }

    }

    //if (txt) return txt;
    return out;
};


// const extractKeywords = (msg) => {
//     // console.log('extractKeywords:', msg.text);
//     const regex = /<p>Keywords:\s*(.*)<\/p>/;
//     const match = msg.text.match(regex);

//     if (match && match[1]) {
//         msg.keywords = match[1].split(',').map(keyword => keyword.trim());
//         msg.keywords = sliceKeywords(msg.keywords);

//         //console.log('msg:', msg);
//         msg.text = msg.text.replace(regex, '').trim()
//     }

//     return msg;
// };

// const sliceKeywords = (keywords) => {
//     if (keywords.length <= 2) {
//         return [];
//     }

//     const maxNumOfKeywords = [0, 3, 4, 5];
//     const randomIndex = Math.floor(Math.random() * maxNumOfKeywords.length);

//     // Get only the second to the second-to-last keywords
//     const slickedKeywords = keywords.slice(1, -1);
//     // Limit to maxNumOfKeywords at most
//     return slickedKeywords.slice(0, maxNumOfKeywords[randomIndex]);

// };