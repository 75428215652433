<template>
  <div class="flight-card">
    <div class="flight-info">

      <div class="flight-section">
        <div class="flight-header">
          <p>Departure <span>{{ departureDate }}</span></p>
          <div class="flight-airline">
            <img class="airline-logo" :src="airlineLogo(departure.airline.logo)">
            <p class="flight-number">{{ departure.flightNumbers }}</p>
          </div>
        </div>
        <div class="flight-details">
          <div class="flight-leg">
            <p>{{ departure.from }}</p>
            <p class="stops">{{ departure.stops }}</p>
            <p>{{ departure.duration }}</p>
            <p>{{ departure.to }}</p>
            <p>{{ departure.date }}</p>
          </div>
        </div>
      </div>  <!-- fight-section -->

      <div class="flight-section">
        <div class="flight-header">
          <p>Return <span>{{ returnDate }}</span></p>
          <div class="flight-airline">
            <img class="airline-logo" :src="airlineLogo(returnFlight.airline.logo)">
            <p class="flight-number">{{ returnFlight.flightNumbers }}</p>
          </div>
        </div>
        <div class="flight-details">
          <div class="flight-leg">
            <p>{{ returnFlight.from }}</p>
            <p class="stops">{{ returnFlight.stops }}</p>
            <p>{{ returnFlight.duration }}</p>
            <p>{{ returnFlight.to }}</p>
            <p>{{ returnFlight.date }}</p>
          </div>
        </div>
      </div> <!-- fight-section -->

    </div> <!-- flight-info -->

    <div class="price-section">
      <span class="price">U${{ price }}</span>
      <p>Valid until {{ validUntil }}</p>
      <button @click="selectFlight" class="select-button">
        Selected
      </button>
    </div> <!-- price -->

  </div> <!-- flight-card -->
</template>


<script>
import { ref } from "vue";

export default {
  setup() {
    const departureDate = ref("July 1, 2024");
    const returnDate = ref("July 17, 2024");
    const departure = ref({
      from: "Toronto Pearson International Airport Terminal 1",
      to: "Tokyo Narita International Airport",
      stops: "1 stop",
      duration: "5h 26m + 10h 00m",
      date: "July 1, 2024",
      airline: {name:"ANA", logo:"ana.jpg"},
      flightNumbers: "ANA JL 888",
    });
    const returnFlight = ref({
      from: "Narita International Airport Terminal 2",
      to: "Toronto Pearson International Airport",
      stops: "1 stop",
      duration: "5h 26m + 10h 00m",
      date: "July 17, 2024",
      airline: {name:"ANA", logo:"ana.jpg"},
      flightNumbers: "ANA JL 888",
    });
    const price = ref("1,000.00");
    const validUntil = ref("October 1, 2024");

    const selectFlight = () => {
      console.log("Flight Selected");
    };

    const airlineLogo = (img) => {
      if (img) {
        return require(`@/assets/airlines/${img}`);
      } else {
        return require(`@/assets/airlines/default.png`);
      }
    };

    return {
      departureDate,
      returnDate,
      departure,
      returnFlight,
      price,
      validUntil,
      selectFlight,
      airlineLogo,
    };
  },
};
</script>

<style scoped>
.flight-card {
  border: 1px solid #ccc;
  padding: 0px 16px;
  border-radius: 8px;
}

.flight-card p {
  margin: 0;
}

.flight-info {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.flight-section {
  padding: 5px 0px;
  position: relative;
}

.flight-section:not(:first-child) {
  border-top: 1px solid var(--border-color);
}

.flight-section span{
    font-weight: bold;
    color: var(--primary-color);
}

.flight-header {
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
}

.flight-airline {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: right;
}

.flight-number p{
  margin-left: 8px;
  font-size: 0.8em;
}

.airline-logo {
  height: 25px;
}

.flight-number {
  display: inline;
  vertical-align: middle;
}

.flight-details {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
}

.flight-leg {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.stops {
  display: flex;
  align-items: center;
  font-weight: bold;
  color: var(--primary-color);
}


.stops::before {
    background: url('@/assets/cityPage/flight_icon_black.png') no-repeat center  center; 
    background-size: contain;
    content: "";
    display: inline-block;
    width: 20px; 
    height: 20px;
    margin-right: 4px;
}

.price-section {
  border-top: 1px solid var(--border-color);
  padding: 16px;
}

.price-section p {
  font-size: 0.8em;
}

.price {
  font-size: 1.2em;
  font-weight: bold;
  color: var(--primary-color);
}

.select-button {
  margin-top: 16px;
  background-color: #4caf50;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.select-button:hover {
  background-color: #45a049;
}
</style>