<template>
    <div class="card" :style="{ backgroundImage: 'url(' + backgroundImage + ')' }">
      <div class="card-content">
        <a href="javascript:void(0)" @click="handleClick">
          <span class="card-title">{{ title }}</span>
        </a>
      </div>
    </div>
</template>
  
  <script>
  export default {
    name: 'PromptCard',
    props: {
      backgroundImage: {
        type: String,
        required: true,
      },
      title: {
        type: String,
        required: true,
      },
      click: {
        type: Function,
        required: false,
      },
    },
    methods: {
      handleClick() {
        if (this.click) {
          this.click();
        }
      },
    },
  };
  </script>
  
  <style scoped>
  .card {
    /* max-width: 150px; */
    /* height: 240px; */
    aspect-ratio: 2 / 3; /* maintains the saspect ratio */
    background-size: cover;
    background-position: center;
    border: 2px solid var(--border-color);
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.4);
    display: flex;
    align-items: flex-end;
    justify-content: center;
    border-radius: 12px;
    overflow: hidden;
    position: relative;
  }

  .card:nth-child(odd) {
    align-items: flex-start;
  }
  
  .card-content {
    width: 100%;
    padding: 10px;
    background: rgba(0, 0, 0, 0.4);
    display: flex;
    align-items: center;
  }
  
  .card-title {
    color: var(--white);
    font-weight: bold;
    font-size: 1.29em;
    text-decoration: none;
    white-space: normal; /* Allow text to wrap */
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3; /* Number of lines to show */
    -webkit-box-orient: vertical;
  }

a {
    text-decoration: none;
}

/* Media query for mobile devices */
@media screen and (max-width: 1024px) {

  .card:nth-child(odd) {
    align-items: flex-end;
  }
}

</style>
  