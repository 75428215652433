<template>
    <div class="hero-button">
      <!-- <a :href="link"> -->
        <img :src="require('@/assets/popup/popupHero/' + icon + '')" :alt="altText" />
      <!-- </a> -->
    </div>
  </template>
  
<script>
export default {
  name: 'HeroButton',
  props: {
    icon: {
      type: String,
      required: true
    },
    altText: {
      type: String,
      default: 'Hero button'
    },
    link: {
      type: String,
      required: false
    }
  }
}
</script>

<style scoped>
.hero-button {
  background-color: white;
  outline: none;
  border-radius: 50%;
  /* box-sizing: border-box; */
  width: 35px;
  height: 35px;
  margin: 0 6px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.9);
}

.hero-button img {
  padding: 6px;
  width: 15px;
  height: 15px;
  margin-left: 4px;
  margin-top: 3px;
  /* align-items: center; */
}

.hero-button img:hover {
  /* background-color: var(--button-hover-color); */
}


  </style>
  