<template>
    <div class="trending-container">
        <HeaderLevel2
            title="Trending Search" 
            :iconSrc="require('@/assets/icons/trends.png')" 
            altText="Trending Search" 
        />
        
        <div class="search-body">
            <SecondarySelectButton
                v-for="(search, index) in randomSearches"
                :key="index"
                :text="search"
                @clicked="submitTrendingSearch(search)"
            />            
            <!-- <button class="search-button" v-for="(search, index) in randomSearches" :key="index" @click="submitTrendingSearch(search)">
                {{ search }}
            </button> -->
        </div>
    </div>
</template>

<script>
import { searches } from '@/data/searches';
import HeaderLevel2 from '@/components/ui/util/HeaderLevel2.vue';
import SecondarySelectButton  from '@/components/ui/util/SecondarySelectButton.vue';

export default {
    name: 'TrendingSearch',
    components: {
        HeaderLevel2,
        SecondarySelectButton,
    },
    data() {
        return {
            searches,
        };
    },
    computed: {
        randomSearches() {
            // Shuffle and select 5 random searches
            return this.shuffleArray(this.searches).slice(0, 7);
        }
    },
    methods: {
        submitTrendingSearch(search) {
            this.$emit('message-submitted', {
                content: {message: search},
                user: true // Assuming the message is from the user
            });
            this.$emit('toggle-popup-submitted');
        },
        shuffleArray(array) {
            let shuffledArray = array.slice(); // Create a copy of the array
            for (let i = shuffledArray.length - 1; i > 0; i--) {
                const j = Math.floor(Math.random() * (i + 1));
                [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
            }
            return shuffledArray;
        },
    },
};
</script>

<style>
.trending-container {
  margin: 20px 5px;
  width: 100%;
}

.search-body {
    margin: 0 15px;
  /* display: flex;
  align-items: center;
  justify-content: center;
  position: relative; */
}



</style>