<template>
    <button class="go-home-button" @click="goHome">&#x21A9;</button>
</template>
  
<script>
import { useStore } from 'vuex';

export default {
    name: 'GoHomeButton',
    setup() {
        const store = useStore();

        const goHome = () => {
            store.dispatch('updateView', 'home');
        };

        return {
            goHome,
        };
    },
};
</script>

<style scoped>

.go-home-button {
    background-color: white;
    outline: none;
    border-radius: 50%;
    border: none;
    width: 35px;
    height: 35px;
    margin: 0 6px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
}

</style>
  