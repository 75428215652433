<template>
    <button 
      @click="handlePublish" 
      :class="['publish-button', { 'published': isPublished }]"
    >
      <img :src="publishIcon" alt="publish" class="button-icon">
      {{ publishText }}
    </button>
  </template>
  
  <script>
  import { defineComponent, ref, computed } from 'vue';
  
  export default defineComponent({
    name: 'PublishButton',
    props: {
      initialState: {
        type: Boolean,
        default: false,
      },
    },
    setup(props) {
      const isPublished = ref(props.initialState);
  
      const publishText = computed(() => (isPublished.value ? "Published" : "Publish"));
      const publishIcon = computed(() => (isPublished.value ? require("@/assets/icons/check_box_checked.png") : require("@/assets/icons/check_box.png")));
  
      const handlePublish = () => {
        isPublished.value = !isPublished.value;
        // console.log("Button clicked, isPublished:", isPublished.value);
      };
  
      return {
        isPublished,
        publishText,
        publishIcon,
        handlePublish,
      };
    },
  });
  </script>
  
  <style scoped>
  .publish-button {
    width: 113px;
    background-color: var(--white);
    color: var(--text-color);
    display: flex;
    align-items: center;
    margin-left: 8px;
    border: 2px solid var(--button-bright-color);
    border-radius: 33px;
    padding: 8px 12px;
    font-size: 0.8em;
    font-weight: bold;
    text-transform: uppercase;
    cursor: pointer;
  }
  
.published {
    background-color: var(--button-bright-color);
    color: var(--white);
  }

  
  .button-icon {
    width: 16px;
    height: 16px;
    margin-right: 5px;
  }
  </style>
  