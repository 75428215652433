<template>
  <div class="hero-container">
    <img src="@/assets/chat/hero_image_only.png" alt="Hero Image" class="hero-image">
  </div>
</template>

<script>
export default {
  name: 'HeroHeader',
}
</script>


<style scoped>
.hero-container {
  display: flex; /* Flexbox layout to center the content */
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  width: 100%;
  height: 20vh; /* Full viewport height or adjust as needed */
}

.hero-image {
  align-items: bottom;
  width: 60%;
  height: auto;
  margin: 0 auto;
  top:0; 
  flex-shrink: 0;
}
</style>