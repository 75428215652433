<template>
  <div class="card-grid">
    <PromptCard
      v-for="(card, index) in cards"
      :key="index"
      :backgroundImage="card.backgroundImage"
      :title="card.title"
      :click="card.click"
    />
  </div>
</template>

<script>
import PromptCard from './PromptCard.vue';
import { addUserMessage } from '@/components/services/MessageService.js';

export default {
  name: 'CardGrid',
  components: {
    PromptCard,
  },
  data() {
    return {
      cards: [
        {
          backgroundImage: require('@/assets/chat/promptCardMenu/inspireme.jpeg'),
          title: 'Inspire me where to go',
          click: () => this.submitMessage('Inspire me where to go'),
        },
        {
          backgroundImage: require('@/assets/chat/promptCardMenu/flights.jpeg'),
          title: 'Find me cheap flight',
          click: () => this.submitMessage('Find me cheap flight'),
        },
        {
          backgroundImage: require('@/assets/chat/promptCardMenu/itinerary.jpeg'),
          title: 'Build me an itinerary',
          click: () => this.submitMessage('Build me an itinerary'),
        },
        {
          backgroundImage: require('@/assets/chat/promptCardMenu/hotels.jpeg'),
          title: 'Show amazing hotels',
          click: () => this.submitMessage('Show amazing hotels'),
        },
      ],
    };
  },
  methods: {
    submitMessage(txt) {
      const msg = {
        content: {message: txt},
        user: true,
      };
      addUserMessage(msg);
    },
  }
};
</script>

<style scoped>
.card-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr; /* Two columns */
  gap:  var(--card-spacing); /* Gap between cards */
  box-sizing: border-box;
  margin: 20px 16px;
}

/* Media query for mobile devices */
@media screen and (max-width: 1024px) {
  .card-grid {
    grid-template-columns: 1fr 1fr; /* Change to two columns on mobile */
  }
}
</style>
