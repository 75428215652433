// cities.js
export const destinations = [


    {
        "city": "Tokyo",
        "country": "Japan",
        "image_desc": "Tokyo Tower",
        "image_url": "https://images.pexels.com/photos/2614818/pexels-photo-2614818.jpeg",
        "main_attractions": ["Tokyo Tower", "Shibuya Crossing", "Senso-ji Temple", "Tokyo Disneyland", "Tsukiji Fish Market"],
        "description": ["Historic monuments", "Japanese gardens", "Traditional tea houses"],
        "expense_rating": 4,
        "average_temperature": 16,
        "coordinates": {
            "lat": 35.682839,
            "lng": 139.759455
        }
    },
    {
        "city": "Kyoto",
        "country": "Japan",
        "image_desc": "Kinkaku-ji",
        "image_url": "https://images.pexels.com/photos/21766390/pexels-photo-21766390/free-photo-of-reflection-of-a-buddhist-temple-on-a-lake.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
        "main_attractions": ["Kinkaku-ji", "Fushimi Inari Shrine", "Arashiyama Bamboo Grove", "Kiyomizu-dera", "Gion"],
        "description": ["Historic temples", "Traditional tea houses", "Beautiful gardens"],
        "expense_rating": 4,
        "average_temperature": 15,
        "coordinates": {
            "lat": 35.0116,
            "lng": 135.7681
        }
    },
    {
        "city": "Osaka",
        "country": "Japan",
        "image_desc": "Osaka Castle",
        "image_url": "https://images.pexels.com/photos/16636148/pexels-photo-16636148/free-photo-of-osaka-castle-main-tower-osaka-japan.jpeg",
        "main_attractions": ["Osaka Castle", "Dotonbori", "Universal Studios Japan", "Umeda Sky Building", "Shinsaibashi"],
        "description": ["Historic castle", "Vibrant nightlife", "Shopping districts"],
        "expense_rating": 4,
        "average_temperature": 17,
        "coordinates": {
            "lat": 34.6937,
            "lng": 135.5023
        }
    },
    {
        "city": "Hiroshima",
        "country": "Japan",
        "image_desc": "Peace Memorial Park",
        "image_url": "https://images.pexels.com/photos/2516579/pexels-photo-2516579.jpeg",
        "main_attractions": ["Peace Memorial Park", "Atomic Bomb Dome", "Itsukushima Shrine", "Hiroshima Castle", "Shukkeien Garden"],
        "description": ["Historical sites", "Peace memorials", "Beautiful shrines"],
        "expense_rating": 3,
        "average_temperature": 15,
        "coordinates": {
            "lat": 34.3853,
            "lng": 132.4553
        }
    },
    {
        "city": "Sapporo",
        "country": "Japan",
        "image_desc": "Odori Park",
        "image_url": "https://images.pexels.com/photos/371634/pexels-photo-371634.jpeg",
        "main_attractions": ["Odori Park", "Sapporo Beer Museum", "Mount Moiwa", "Susukino", "Maruyama Park"],
        "description": ["Winter festivals", "Beer museum", "Scenic views"],
        "expense_rating": 3,
        "average_temperature": 10,
        "coordinates": {
            "lat": 43.0618,
            "lng": 141.3545
        }
    },
    {
        "city": "Seoul",
        "country": "South Korea",
        "image_desc": "Gyeongbokgung Palace",
        "image_url": "https://images.pexels.com/photos/220201/pexels-photo-220201.jpeg",
        "main_attractions": ["Gyeongbokgung Palace", "N Seoul Tower", "Bukchon Hanok Village", "Myeongdong", "Changdeokgung"],
        "description": ["Historic palaces", "Modern skyscrapers", "Vibrant shopping"],
        "expense_rating": 4,
        "average_temperature": 12,
        "coordinates": {
            "lat": 37.5665,
            "lng": 126.9780
        }
    },
    {
        "city": "Busan",
        "country": "South Korea",
        "image_desc": "Haeundae Beach",
        "image_url": "https://images.pexels.com/photos/1200248/pexels-photo-1200248.jpeg",
        "main_attractions": ["Haeundae Beach", "Gamcheon Culture Village", "Jagalchi Fish Market", "Beomeosa Temple", "Gwangalli Beach"],
        "description": ["Beautiful beaches", "Cultural villages", "Vibrant markets"],
        "expense_rating": 3,
        "average_temperature": 15,
        "coordinates": {
            "lat": 35.1796,
            "lng": 129.0756
        }
    },
    {
        "city": "Incheon",
        "country": "South Korea",
        "image_desc": "Incheon Chinatown",
        "image_url": "https://images.pexels.com/photos/208732/pexels-photo-208732.jpeg",
        "main_attractions": ["Incheon Chinatown", "Songdo Central Park", "Wolmido Island", "Incheon Bridge", "Jeondeungsa Temple"],
        "description": ["Chinatown", "Modern architecture", "Scenic parks"],
        "expense_rating": 3,
        "average_temperature": 13,
        "coordinates": {
            "lat": 37.4563,
            "lng": 126.7052
        }
    },
    {
        "city": "Jeju",
        "country": "South Korea",
        "image_desc": "Seongsan Ilchulbong",
        "image_url": "https://images.pexels.com/photos/167699/pexels-photo-167699.jpeg",
        "main_attractions": ["Seongsan Ilchulbong", "Hallasan National Park", "Jeongbang Waterfall", "Manjanggul Cave", "Seongeup Folk Village"],
        "description": ["Natural landmarks", "Beautiful waterfalls", "Scenic caves"],
        "expense_rating": 3,
        "average_temperature": 17,
        "coordinates": {
            "lat": 33.4996,
            "lng": 126.5312
        }
    },
    {
        "city": "Nagoya",
        "country": "Japan",
        "image_desc": "Nagoya Castle",
        "image_url": "https://images.pexels.com/photos/213200/pexels-photo-213200.jpeg",
        "main_attractions": ["Nagoya Castle", "Atsuta Shrine", "Osu Shopping District", "Toyota Commemorative Museum", "Port of Nagoya Aquarium"],
        "description": ["Historic castles", "Shopping districts", "Cultural landmarks"],
        "expense_rating": 3,
        "average_temperature": 16,
        "coordinates": {
            "lat": 35.1815,
            "lng": 136.9066
        }
    },
    {
        "city": "Fukuoka",
        "country": "Japan",
        "image_desc": "Tochoji Temple",
        "image_url": "https://images.pexels.com/photos/19385241/pexels-photo-19385241/free-photo-of-tochoji-temple-in-fukuoka.jpeg",
        "main_attractions": ["Fukuoka Tower", "Ohori Park", "Canal City Hakata", "Fukuoka Castle", "Hakata Ramen"],
        "description": ["Modern tower", "Beautiful parks", "Shopping malls"],
        "expense_rating": 3,
        "average_temperature": 18,
        "coordinates": {
            "lat": 33.5904,
            "lng": 130.4017
        }
    },


    {
        "city": "Paris",
        "country": "France",
        "image_desc": "Eiffel Tower",
        "image_url": "https://images.pexels.com/photos/338515/pexels-photo-338515.jpeg",
        "main_attractions": ["Eiffel Tower", "Louvre Museum", "Notre-Dame Cathedral", "Seine River Cruise", "Montmartre"],
        "description": ["Historic monuments", "Art museums", "Charming cafes"],
        "expense_rating": 4,
        "average_temperature": 12,
        "coordinates": {
            "lat": 48.8566,
            "lng": 2.3522
        }
    },
    {
        "city": "New York City",
        "country": "USA",
        "image_desc": "Statue of Liberty",
        "image_url": "https://images.pexels.com/photos/466685/pexels-photo-466685.jpeg",
        "main_attractions": ["Statue of Liberty", "Central Park", "Times Square", "Empire State Building", "Broadway Shows"],
        "description": ["Skyscrapers", "Bustling streets", "Iconic landmarks"],
        "expense_rating": 5,
        "average_temperature": 13,
        "coordinates": {
            "lat": 40.7128,
            "lng": -74.0060
        }
    },
    {
        "city": "Rome",
        "country": "Italy",
        "image_desc": "Colosseum",
        "image_url": "https://images.pexels.com/photos/356854/pexels-photo-356854.jpeg",
        "main_attractions": ["Colosseum", "Vatican City", "Trevi Fountain", "Pantheon", "Roman Forum"],
        "description": ["Ancient ruins", "Vatican City", "Renaissance art"],
        "expense_rating": 3,
        "average_temperature": 16,
        "coordinates": {
            "lat": 41.9028,
            "lng": 12.4964
        }
    },
    {
        "city": "Barcelona",
        "country": "Spain",
        "image_desc": "Sagrada Familia",
        "image_url": "https://images.pexels.com/photos/1268871/pexels-photo-1268871.jpeg",
        "main_attractions": ["Sagrada Familia", "Park Güell", "La Rambla", "Gothic Quarter", "Camp Nou"],
        "description": ["Modernist architecture", "Vibrant nightlife", "Mediterranean beaches"],
        "expense_rating": 3,
        "average_temperature": 18,
        "coordinates": {
            "lat": 41.3851,
            "lng": 2.1734
        }
    },
    {
        "city": "London",
        "country": "England",
        "image_desc": "Big Ben",
        "image_url": "https://images.pexels.com/photos/460672/pexels-photo-460672.jpeg",
        "main_attractions": ["Big Ben", "London Eye", "Tower of London", "Buckingham Palace", "British Museum"],
        "description": ["Historic landmarks", "World-class museums", "Royal palaces"],
        "expense_rating": 5,
        "average_temperature": 11,
        "coordinates": {
            "lat": 51.5074,
            "lng": -0.1278
        }
    },
    {
        "city": "Dubai",
        "country": "UAE",
        "image_desc": "Burj Khalifa",
        "image_url": "https://images.pexels.com/photos/2218885/pexels-photo-2218885.jpeg",
        "main_attractions": ["Burj Khalifa", "Dubai Mall", "Palm Jumeirah", "Dubai Marina", "Desert Safari"],
        "description": ["Modern skyscrapers", "Luxury shopping", "Desert adventures"],
        "expense_rating": 5,
        "average_temperature": 28,
        "coordinates": {
            "lat": 25.276987,
            "lng": 55.296249
        }
    },
    {
        "city": "Istanbul",
        "country": "Turkey",
        "image_desc": "Hagia Sophia",
        "image_url": "https://images.pexels.com/photos/1611046/pexels-photo-1611046.jpeg",
        "main_attractions": ["Hagia Sophia", "Blue Mosque", "Topkapi Palace", "Grand Bazaar", "Bosphorus Cruise"],
        "description": ["Historic mosques", "Vibrant bazaars", "Ottoman palaces"],
        "expense_rating": 2,
        "average_temperature": 15,
        "coordinates": {
            "lat": 41.0082,
            "lng": 28.9784
        }
    },
    {
        "city": "Bangkok",
        "country": "Thailand",
        "image_desc": "Wat Arun",
        "image_url": "https://images.pexels.com/photos/2071650/pexels-photo-2071650.jpeg",
        "main_attractions": ["Wat Arun", "Grand Palace", "Chatuchak Market", "Floating Markets", "Khao San Road"],
        "description": ["Ornate temples", "Bustling markets", "Street food"],
        "expense_rating": 2,
        "average_temperature": 28,
        "coordinates": {
            "lat": 13.7563,
            "lng": 100.5018
        }
    },
    {
        "city": "Sydney",
        "country": "Australia",
        "image_desc": "Sydney Opera House",
        "image_url": "https://images.pexels.com/photos/2193300/pexels-photo-2193300.jpeg",
        "main_attractions": ["Sydney Opera House", "Sydney Harbour Bridge", "Bondi Beach", "Taronga Zoo", "The Rocks"],
        "description": ["Iconic opera house", "Stunning beaches", "Harbour cruises"],
        "expense_rating": 4,
        "average_temperature": 18,
        "coordinates": {
            "lat": -33.8688,
            "lng": 151.2093
        }
    },
    {
        "city": "Moscow",
        "country": "Russia",
        "image_desc": "Red Square",
        "image_url": "https://images.pexels.com/photos/672532/pexels-photo-672532.jpeg",
        "main_attractions": ["Red Square", "Kremlin", "Saint Basil's Cathedral", "Bolshoi Theatre", "Gorky Park"],
        "description": ["Historic landmarks", "Rich cultural heritage", "Beautiful architecture"],
        "expense_rating": 3,
        "average_temperature": 5,
        "coordinates": {
            "lat": 55.7558,
            "lng": 37.6173
        }
    },
    {
        "city": "Beijing",
        "country": "China",
        "image_desc": "Great Wall of China",
        "image_url": "https://images.pexels.com/photos/258606/pexels-photo-258606.jpeg",
        "main_attractions": ["Great Wall of China", "Forbidden City", "Tiananmen Square", "Summer Palace", "Temple of Heaven"],
        "description": ["Historic monuments", "Ancient architecture", "Cultural landmarks"],
        "expense_rating": 3,
        "average_temperature": 13,
        "coordinates": {
            "lat": 39.9042,
            "lng": 116.4074
        }
    },
    {
        "city": "Cairo",
        "country": "Egypt",
        "image_desc": "Pyramids of Giza",
        "image_url": "https://images.pexels.com/photos/248771/pexels-photo-248771.jpeg",
        "main_attractions": ["Pyramids of Giza", "Sphinx", "Egyptian Museum", "Khan El Khalili", "Nile River"],
        "description": ["Ancient pyramids", "Historic sites", "Cultural experiences"],
        "expense_rating": 2,
        "average_temperature": 22,
        "coordinates": {
            "lat": 30.0444,
            "lng": 31.2357
        }
    },
    {
        "city": "Rio de Janeiro",
        "country": "Brazil",
        "image_desc": "Christ the Redeemer",
        "image_url": "https://images.pexels.com/photos/3244513/pexels-photo-3244513.jpeg",
        "main_attractions": ["Christ the Redeemer", "Sugarloaf Mountain", "Copacabana Beach", "Ipanema Beach", "Maracanã Stadium"],
        "description": ["Iconic statue", "Beautiful beaches", "Vibrant culture"],
        "expense_rating": 3,
        "average_temperature": 23,
        "coordinates": {
            "lat": -22.9068,
            "lng": -43.1729
        }
    },
    {
        "city": "Mexico City",
        "country": "Mexico",
        "image_desc": "Zócalo",
        "image_url": "https://images.pexels.com/photos/2231023/pexels-photo-2231023.jpeg",
        "main_attractions": ["Zócalo", "Chapultepec Park", "Frida Kahlo Museum", "Teotihuacan", "Palacio de Bellas Artes"],
        "description": ["Historic center", "Cultural landmarks", "Vibrant city life"],
        "expense_rating": 2,
        "average_temperature": 16,
        "coordinates": {
            "lat": 19.4326,
            "lng": -99.1332
        }
    },
    {
        "city": "Amsterdam",
        "country": "Netherlands",
        "image_desc": "Canals",
        "image_url": "https://images.pexels.com/photos/378570/pexels-photo-378570.jpeg",
        "main_attractions": ["Canals", "Rijksmuseum", "Van Gogh Museum", "Anne Frank House", "Vondelpark"],
        "description": ["Historic canals", "Art museums", "Bicycle-friendly city"],
        "expense_rating": 4,
        "average_temperature": 10,
        "coordinates": {
            "lat": 52.3676,
            "lng": 4.9041
        }
    },
    {
        "city": "Buenos Aires",
        "country": "Argentina",
        "image_desc": "Obelisk",
        "image_url": "https://images.pexels.com/photos/982283/pexels-photo-982283.jpeg",
        "main_attractions": ["Obelisk", "La Boca", "Recoleta Cemetery", "Teatro Colón", "Palermo"],
        "description": ["Historic landmarks", "Tango culture", "Vibrant neighborhoods"],
        "expense_rating": 2,
        "average_temperature": 18,
        "coordinates": {
            "lat": -34.6037,
            "lng": -58.3816
        }
    },
    {
        "city": "Athens",
        "country": "Greece",
        "image_desc": "Acropolis",
        "image_url": "https://images.pexels.com/photos/164248/pexels-photo-164248.jpeg",
        "main_attractions": ["Acropolis", "Parthenon", "Ancient Agora", "Plaka", "National Archaeological Museum"],
        "description": ["Ancient ruins", "Historic landmarks", "Greek culture"],
        "expense_rating": 3,
        "average_temperature": 18,
        "coordinates": {
            "lat": 37.9838,
            "lng": 23.7275
        }
    },
    {
        "city": "Los Angeles",
        "country": "USA",
        "image_desc": "Hollywood Sign",
        "image_url": "https://images.pexels.com/photos/277344/pexels-photo-277344.jpeg",
        "main_attractions": ["Hollywood Sign", "Griffith Observatory", "Santa Monica Pier", "Venice Beach", "Universal Studios"],
        "description": ["Entertainment industry", "Beautiful beaches", "Cultural diversity"],
        "expense_rating": 4,
        "average_temperature": 18,
        "coordinates": {
            "lat": 34.0522,
            "lng": -118.2437
        }
    },
    {
        "city": "Hong Kong",
        "country": "China",
        "image_desc": "Victoria Peak",
        "image_url": "https://images.pexels.com/photos/236820/pexels-photo-236820.jpeg",
        "main_attractions": ["Victoria Peak", "Tsim Sha Tsui", "Hong Kong Disneyland", "Temple Street Night Market", "Lantau Island"],
        "description": ["Skyscrapers", "Shopping districts", "Cultural landmarks"],
        "expense_rating": 4,
        "average_temperature": 23,
        "coordinates": {
            "lat": 22.3193,
            "lng": 114.1694
        }
    },
    {
        "city": "Vienna",
        "country": "Austria",
        "image_desc": "Schönbrunn Palace",
        "image_url": "https://images.pexels.com/photos/347734/pexels-photo-347734.jpeg",
        "main_attractions": ["Schönbrunn Palace", "Hofburg Palace", "St. Stephen's Cathedral", "Belvedere Palace", "Prater"],
        "description": ["Historic palaces", "Classical music", "Cultural heritage"],
        "expense_rating": 4,
        "average_temperature": 11,
        "coordinates": {
            "lat": 48.2082,
            "lng": 16.3738
        }
    },
    {
        "city": "San Francisco",
        "country": "USA",
        "image_desc": "Golden Gate Bridge",
        "image_url": "https://images.pexels.com/photos/733174/pexels-photo-733174.jpeg",
        "main_attractions": ["Golden Gate Bridge", "Alcatraz Island", "Fisherman's Wharf", "Lombard Street", "Chinatown"],
        "description": ["Iconic bridge", "Historic sites", "Cultural diversity"],
        "expense_rating": 5,
        "average_temperature": 14,
        "coordinates": {
            "lat": 37.7749,
            "lng": -122.4194
        }
    },
    {
        "city": "Lisbon",
        "country": "Portugal",
        "image_desc": "Belém Tower",
        "image_url": "https://images.pexels.com/photos/279523/pexels-photo-279523.jpeg",
        "main_attractions": ["Belém Tower", "Jerónimos Monastery", "Alfama", "São Jorge Castle", "Lisbon Oceanarium"],
        "description": ["Historic landmarks", "Beautiful views", "Cultural heritage"],
        "expense_rating": 3,
        "average_temperature": 17,
        "coordinates": {
            "lat": 38.7223,
            "lng": -9.1393
        }
    },
    {
        "city": "Venice",
        "country": "Italy",
        "image_desc": "Grand Canal",
        "image_url": "https://images.pexels.com/photos/208733/pexels-photo-208733.jpeg",
        "main_attractions": ["Grand Canal", "St. Mark's Basilica", "Rialto Bridge", "Doge's Palace", "Murano"],
        "description": ["Historic canals", "Beautiful architecture", "Rich history"],
        "expense_rating": 4,
        "average_temperature": 14,
        "coordinates": {
            "lat": 45.4408,
            "lng": 12.3155
        }
    }



];

