<template>
    <div class="itinerary-container">
      <HeaderLevel2
        title="Popular Itinerary" 
        :iconSrc="require('@/assets/icons/itinerary_icon.png')" 
        altText="Popular Itinerary"
      />
      <CarouselSlide>
        <ItineraryCard v-for="itinerary in shownRandom" :key="itinerary.city" :itinerary="itinerary" @clicked="handleClick"/>
      </CarouselSlide>
    </div>
</template>

<script>
import { itineraries } from '@/data/itineraries.js';
import HeaderLevel2 from '@/components/ui/util/HeaderLevel2.vue';
import CarouselSlide from '@/components/ui/util/CarouselSlide.vue';
import ItineraryCard from './ItineraryCard.vue';


export default {
  name: 'PopularItinerary',
  components: {
    HeaderLevel2,
    CarouselSlide,
    ItineraryCard,
  },
  data() {
    return {
        itineraries,
        shownRandom: [],
    };
  },
  mounted() {
    // console.log('Parent component name:', this.$parent?.$options.name);
    // console.log('Parent component instance:', this.$parent);
    this.shownRandom = this.getRandom(itineraries);
    //console.log(this.shownRandom);
  },
  methods: {
    getRandom(data) {
      const maxNum = 5;
      let shuffled = data.sort(() => 0.5 - Math.random());
      // console.log(shuffled.slice(0, numOfCitiesShown));
      return shuffled.slice(0, maxNum);
    },
    handleClick() {
        this.$emit('city-submitted');
    },
  },  
};
</script>

<style scoped>
.itinerary-container {
  margin: 20px 5px;
}


</style>