<template>
    <div class="chat-container">
      <div class="chat-header">
        <MenuBar />
        <button class="toggle-button" @click="togglePopup">></button>
      </div>
      <div class="chat-body" id="chat-body">
        <HeroHeader />
        <!-- <SidebarMenu /> -->
        <PromptCardMenu />  
        <ChatWindow ref="chatWindow" @message-submitted="addUserMessage" />
        <LoadingDots v-if="isLoading" />
      </div>
      <div class="chat-footer">
        <InputArea @message-submitted="addUserMessage" />
      </div>
    </div>
  </template>
  
  <script>
  import { computed, onMounted, onBeforeUnmount } from 'vue';
  import { useStore } from 'vuex';
  import MenuBar from './MenuBar.vue';
  import HeroHeader from './HeroHeader.vue';
  // import SidebarMenu from './SidebarMenu.vue';
  import PromptCardMenu from './PromptCardMenu.vue';
  import ChatWindow from './ChatWindow.vue';
  import LoadingDots from './LoadingDots.vue';
  import InputArea from './InputArea.vue';
  import { isLoading } from '@/states/isLoading.js';
  import { addUserMessage } from '@/components/services/MessageService.js';
  
  export default {
    name: 'ChatPage',
    components: {
      MenuBar,
      HeroHeader,
      // SidebarMenu,
      PromptCardMenu,
      ChatWindow,
      LoadingDots,
      InputArea,
    },
    setup() {
      const store = useStore();
      const isPopupVisible = computed(() => store.state.isPopupVisible);
      const stateMessages = computed(() => store.state.messages);
      const isMobile = computed(() => store.state.isMobile);
  
      const handleResize = () => {
        store.dispatch('updateMobile', window.innerWidth <= 768);
      };
  
      const togglePopup = () => {
        store.dispatch('updatePopupVisible',!store.state.isPopupVisible);
      };
  
      onMounted(() => {
        window.addEventListener('resize', handleResize);
        handleResize();
      });
  
      onBeforeUnmount(() => {
        window.removeEventListener('resize', handleResize);
      });
  
      return {
        isPopupVisible,
        isLoading,
        stateMessages,
        isMobile,
        togglePopup,
        addUserMessage,
      };
    },
  };
  </script>
  
  <style scoped>
  .chat-container {
    display: flex;
    flex-direction: column;
    height: 100dvh;
    width: 100%;
    z-index: 1;
    overflow-y: auto;
    overflow-x: hidden;
    /* border: 1px solid var(--red); */
  }
  
  .chat-header {
    padding: 10px;
    text-align: center;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .chat-body {
    flex: 1;
    padding: 0px;
    overflow-y: auto;
    overflow-x: hidden;
    width: 100%;
    box-sizing: border-box;
    /* border: 1px solid var(--blue); */
  }
  

  /* .chat-body .child-element{
    max-width: 100%;
    bnox-sizing: border-box;
  } */

  .chat-footer {
    bottom: 0;
    margin: 0px;
    overflow-x: hidden;
  }
  
  .toggle-button {
    background-color: white;
    outline: none;
    border-radius: 50%;
    border: none;
    width: 35px;
    height: 35px;
    margin: 0 6px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
    display: none;
  }
  
  /* Responsive design for mobile */
  @media (max-width: 768px) {
    .toggle-button {
      display: block;
    }
  }
  </style>
  